@import "./normalize.css";
@import "./color.scss";
@import "./animation.scss";

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-UltraLight.eot");
  src: local("RF Tone UltraLight"), local("RFTone-UltraLight"),
    url("../fonts/RFTone-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-UltraLight.woff2") format("woff2"), url("../fonts/RFTone-UltraLight.woff") format("woff"),
    url("../fonts/RFTone-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-Light.eot");
  src: local("RF Tone Light"), local("RFTone-Light"),
    url("../fonts/RFTone-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-Light.woff2") format("woff2"), url("../fonts/RFTone-Light.woff") format("woff"),
    url("../fonts/RFTone-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-ExtraLight.eot");
  src: local("RF Tone ExtraLight"), local("RFTone-ExtraLight"),
    url("../fonts/RFTone-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-ExtraLight.woff2") format("woff2"), url("../fonts/RFTone-ExtraLight.woff") format("woff"),
    url("../fonts/RFTone-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-Regular.eot");
  src: local("RF Tone Regular"), local("RFTone-Regular"),
    url("../fonts/RFTone-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-Regular.woff2") format("woff2"), url("../fonts/RFTone-Regular.woff") format("woff"),
    url("../fonts/RFTone-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-Bold.eot");
  src: local("RF Tone Bold"), local("RFTone-Bold"), url("../fonts/RFTone-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-Bold.woff2") format("woff2"), url("../fonts/RFTone-Bold.woff") format("woff"),
    url("../fonts/RFTone-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-SemiBold.eot");
  src: local("RF Tone SemiBold"), local("RFTone-SemiBold"),
    url("../fonts/RFTone-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-SemiBold.woff2") format("woff2"), url("../fonts/RFTone-SemiBold.woff") format("woff"),
    url("../fonts/RFTone-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "RF Tone";
  src: url("../fonts/../fonts/RFTone-Black.eot");
  src: local("RF Tone Black"), local("RFTone-Black"),
    url("../fonts/RFTone-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RFTone-Black.woff2") format("woff2"), url("../fonts/RFTone-Black.woff") format("woff"),
    url("../fonts/RFTone-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: "RF Tone";
  font-weight: 300;
  background: $white;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: $blueActive;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  min-height: 100vh;
}
.content.mainpage {
  background-color: $black;
}
.main_wrapper {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.main__button {
  width: 238px;
  height: 44px;
  border-radius: 12px;
  background: $blue;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  flex-shrink: 0;

  &:hover {
    background: $bluehover;
  }
}

@media (max-width: 1200px) {
  .main_wrapper {
    padding: 0 40px;
  }
}

@media (max-width: 680px) {
  .main_wrapper {
    padding: 0 15px;
  }
  .main__button {
    font-size: 14px;
    height: 40px;
  }
}

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease-in-out;

  &.hide {
    opacity: 0;
  }

  & .modal__block {
    max-width: 720px;
    width: 100%;
    border-radius: 10px;
    height: 500px;
    background: #f5f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
      background: none;
    }

    & .call_back-form {
      max-width: 460px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .call_back-input {
        width: 340px;
        height: 50px;
        border-radius: 5px;
        margin-bottom: 18px;
        color: #828282;
        border: 1px solid #d1d1d1;
        padding-left: 15px;
        outline: none;
        &::placeholder {
          color: #b4b4b4;
        }

        &:nth-child(2) {
          margin-bottom: 22px;
        }
      }
      & p {
        margin: 0;
        font-size: 14px;
        text-align: left;
        color: $grayText;
        max-width: 385px;

        & a {
          color: $blackText;
          &:hover {
            color: $grayText;
          }
        }
      }

      & > div {
        display: flex;
        margin-bottom: 15px;
      }
      & .call_back-checkbox {
        margin-right: 14px;
        margin-left: 38px;
      }

      & .call_back-button {
        max-width: 340px;
        width: 100%;
        height: 50px;
      }
    }

    & h6 {
      color: $blue;
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    & h4 {
      font-size: 50px;
      font-weight: 700;
      color: $blackText;
      margin-bottom: 6px;
      text-align: center;

      & span {
        color: $blue;
      }
    }
    & .big-title {
      font-size: 40px;
    }
    & p {
      font-size: 22px;
      font-weight: 300;
      color: $grayText;
      text-align: center;
      margin-bottom: 38px;
    }

    & .close_modal {
      width: 19px;
      height: 19px;
      right: 15px;
      top: 15px;
      position: absolute;
      cursor: pointer;
      background: url(../img/close.svg) center no-repeat;
    }
  }
}

@media (max-width: 760px) {
  .modal__wrapper {
    & .modal__block {
      max-width: 350px;
      height: 382px;
      & h4 {
        font-size: 30px;
        margin-bottom: 1px;
        text-align: center;
      }
      & .big-title {
        font-size: 20px;
      }
      & p {
        font-size: 14px;
        margin-bottom: 26px;
      }
      & .call_back-form {
        & > div {
          align-items: center;
          max-width: 295px;
        }
        & .call_back-input {
          width: 250px;
          height: 40px;
          box-sizing: border-box;
          margin-bottom: 10px;
          &:nth-child(2) {
            margin-bottom: 10px;
          }
        }
        & p {
          font-size: 10px;
        }
        & .call_back-button {
          width: 250px;
          height: 40px;
          border-radius: 10px;
        }
        & .call_back-checkbox {
          margin-left: 25px;
        }
      }
    }
  }
}

.main_wrapper-banner__price {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  & .banner__price {
    position: absolute;
    bottom: 38px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .banner__price-light {
      position: absolute;
      z-index: 0;
      background: #006cff;
      filter: blur(50px);
      transform: rotate(-18.21deg);
      width: 230px;
      height: 100px;
      opacity: 0.5;
      left: -13px;
    }

    & .banner__prices {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;

      & .newprice {
        color: $blue;
        font-size: 36px;
        font-weight: 600;
        margin: 0 13px 0 0;
      }
      & .oldprice {
        color: #c5c3c6;
        font-size: 20px;
        font-weight: 300;
        text-decoration: line-through;
      }
    }

    & .descr {
      position: relative;
      z-index: 1;
      color: #c5c3c6;
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      margin-left: 55px;
      line-height: 1.3em;
    }
  }
}

@media (max-width: 1440px) {
  .main_wrapper-banner__price .banner__price {
    align-items: flex-start;
    right: 62px;
  }
  .main_wrapper-banner__price .banner__price .banner__price-light {
    width: 200px;
  }
  .main_wrapper-banner__price .banner__price .banner__prices {
    flex-direction: column;
    align-items: flex-start;
  }
  .main_wrapper-banner__price .banner__price .banner__prices .newprice {
    margin: 0 0 2px 0;
  }
  .main_wrapper-banner__price .banner__price .banner__prices .oldprice {
    margin: 0 0 12px 0;
  }
  .main_wrapper-banner__price .banner__price .descr {
    margin: 0;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .main_wrapper-banner__price .banner__price .banner__prices .newprice {
    font-size: 26px;
  }
  .main_wrapper-banner__price .banner__price .banner__prices .oldprice {
    font-size: 16px;
    margin: 0px 0 2px 0;
  }
  .main_wrapper-banner__price .banner__price .descr {
    font-size: 14px;
  }
  .main_wrapper-banner__price .banner__price {
    right: 85px;
    bottom: 15px;
  }
  .main_wrapper-banner__price .banner__price .banner__price-light {
    width: 150px;
    left: -35px;
  }

}

@media (max-width: 420px) {
  .main_wrapper-banner__price .banner__price{
    right: 25px;
  }
  .main_wrapper-banner__price .banner__price .banner__prices .newprice{
    font-size: 22px;
  }
  .main_wrapper-banner__price .banner__price .banner__prices .oldprice{
    margin: 0 0 5px 0;
  }
  .main_wrapper-banner__price .banner__price .descr{
    font-size: 12px;
  }
}