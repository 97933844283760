@import "./color.scss";

.wrapper__delivery {
  & .main__block_delivery {
    position: relative;
    height: 745px;
    width: 100%;
    background: url(../img/delivery/delivery-bg.png) center no-repeat;
    background-size: cover;
    margin-bottom: 100px;
    transition: all 350ms ease-in-out;
  }

  & .delivery__blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 100px;

    & .delivery__block {
      width: 50%;

      & h5 {
        color: $blackText;
        font-size: 60px;
        font-weight: 300;
        line-height: 1em;
        margin-bottom: 40px;
        transition: all 350ms ease-in-out;
      }
      & img {
        transition: all 350ms ease-in-out;
      }
      & ul {
        list-style: none;
        padding: 0;
        margin: 0;
        & li {
          color: $grayText;
          padding-left: 50px;
          min-height: 30px;
          font-size: 22px;
          margin-bottom: 20px;
          transition: all 350ms ease-in-out;

          &:nth-child(1) {
            background: url(../img/test-drive/delivery.svg) left top no-repeat;
          }
          &:nth-child(2) {
            background: url(../img/test-drive/phone.svg) left top no-repeat;
          }
          &:nth-child(3) {
            background: url(../img/test-drive/home.svg) left top no-repeat;
          }
          &:nth-child(4) {
            background: url(../img/test-drive/assembly.svg) left top no-repeat;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper__delivery {
    & .main__block_delivery {
      height: 630px;
      min-height: 100%;
    }
    & .delivery__blocks {
      margin-bottom: 10px;
      & .delivery__block {
        & img {
          width: 95%;
        }
        & h5 {
          font-size: 50px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper__delivery {
    & .delivery__blocks {
      flex-direction: column-reverse;
      & .delivery__block {
        width: 100%;

        &:last-child {
          margin-bottom: 60px;
        }
        &:first-child {
          text-align: center;
        }

        & img {
          width: auto;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .wrapper__delivery {
    & .main__block_delivery {
      background: url(../img/delivery/delivery-ap-bg.png) center no-repeat;
      background-size: cover;
      background-position-x: 0;
    }
  }
}

@media (max-width: 760px) {
  .wrapper__delivery {
    & .main__block_delivery {
      height: 630px;
      margin-bottom: 60px;
    }

    & .delivery__blocks {
      & .delivery__block {
        & h5 {
          font-size: 40px;
          line-height: 1.1em;
        }
        & ul li {
          font-size: 18px;
          margin-bottom: 30px;
          &:nth-child(2) {
            max-width: 275px;
          }
          & br {
            display: none;
          }
        }

        & img {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .wrapper__delivery {
    & .main__block_delivery {
      background: url(../img/delivery/delivery-m-bg.png) center no-repeat;
      background-size: cover;
      background-position-x: -189px;
    }
  }
}
