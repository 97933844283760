@import "./color.scss";

.wrapper__aboutcompany {
  & .main__block_aboutcompany {
    position: relative;
    height: 745px;
    width: 100%;
    background: url(../img/aboutcompany/aboutcompany-bg.png) center no-repeat;
    background-size: cover;
    margin-bottom: 130px;
    transition: all 350ms ease-in-out;

    & div {
      display: flex;
      flex-direction: column;
      transition: opacity 250ms ease-in-out;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      & img {
        padding: 0 22px 45px;
        border-bottom: 1px solid $white;
        margin-bottom: 28px;
      }
      & span {
        font-size: 24px;
        font-weight: 300;
        color: $white;
        text-align: center;
      }
    }
  }

  & .aboutcompany__blocks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    align-items: center;

    & .aboutcompany__block {
      width: 50%;

      & h1 {
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 20px;
        transition: all 350ms ease-in-out;
      }

      & p {
        font-size: 18px;
        font-weight: 300;
        max-width: 500px;
        color: $grayText;
        transition: all 350ms ease-in-out;

        & span {
          font-weight: 600;
        }
      }

      & div {
        border-radius: 20px;
        overflow: hidden;
        width: 570px;
        height: 487px;

        & img {
          height: 100%;
          transform: translate(-160px, 0);
          transition: all 350ms ease-in-out;
        }
      }
    }
  }

  & .short_info__blocks {
    display: flex;
    margin-bottom: 100px;

    & .short_info__block {
      width: 33.333%;
      background: #fbfbfb;
      border: 1px solid #b7b7b7;
      border-radius: 10px;
      padding: 60px 0 60px 41px;

      & > span {
        color: $blue;
        font-size: 100px;
        font-weight: 300;
        margin-bottom: 10px;
        line-height: 1em;

        & span:last-child {
          color: $blue;
          font-size: 58px;
          font-weight: 300;
        }
      }
      & p {
        font-size: 30px;
        font-weight: 300;
        color: $blackText;
      }
      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }
    }
  }
  & .history_company {
    margin-bottom: 100px;
    &__blocks {
      display: flex;
      flex-wrap: wrap;

      & .history_company__block {
        width: 50%;

        & div {
          max-width: 500px;
          height: 480px;
          overflow: hidden;
          border-radius: 20px;
          margin-bottom: 20px;
          position: relative;

          & img {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            transition: opacity 350ms ease-in-out;
          }
        }
        & h6 {
          font-size: 18px;
          font-weight: 600;
          color: $blackText;
          margin-bottom: 6px;
          transition: opacity 350ms ease-in-out;
        }

        &:first-child {
          & p {
            font-size: 16px;
            font-weight: 300;
            color: $grayText;
            transition: opacity 350ms ease-in-out;
          }
        }

        & h2 {
          padding-top: 40px;
          font-size: 50px;
          font-weight: 300;
          margin-bottom: 32px;
          line-height: 1em;
          transition: opacity 350ms ease-in-out;
        }

        &:last-child {
          & p {
            font-size: 18px;
            color: $grayText;
            margin-bottom: 10px;

            & span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .history_massager {
    color: $white;
    background: #131313 url(../img/aboutcompany/history-massager.png) top center no-repeat;
    background-size: contain;
    margin-bottom: 140px;
    &__blocks {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 115px;
      & .history_massager__block {
        width: 50%;

        & h3 {
          font-size: 50px;
          font-weight: 300;
          line-height: 1.1em;
          margin-top: 100px;
          margin-bottom: 30px;
          transition: all 350ms ease-in-out;
        }

        & p {
          font-size: 18px;
          font-weight: 300;
          margin-bottom: 15px;
          max-width: 485px;
          transition: all 350ms ease-in-out;
        }

        &:last-child {
          text-align: end;

          & img {
            margin-top: 52px;
            transition: all 350ms ease-in-out;
          }
        }
      }
    }

    & .histor_massager__bs {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 115px;

      & .histor_massager__b {
        width: 25%;
        text-align: center;

        &:nth-child(1){
          transition: all 250ms ease-in-out;
        }
        &:nth-child(2){
          transition: all 450ms ease-in-out;
        }

        &:nth-child(3){
          transition: all 650ms ease-in-out;
        }
        &:nth-child(4){
          transition: all 850ms ease-in-out;
        }

        & img {
          margin-bottom: 24px;
        }

        & span {
          display: block;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }

  & .massage_masters-top {
    margin-bottom: 50px;
    & h5 {
      font-size: 60px;
      font-weight: 300;
      color: $blackText;
      line-height: 1em;
      padding-bottom: 30px;
      border-bottom: 1px solid #93969952;
      margin-bottom: 80px;
      transition: all 350ms ease-in-out;

      & > span {
        color: $blue;
        display: block;
      }
    }
    & div {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 50px;
      border-bottom: 1px solid #93969952;

      & img {
        order: 1;
        width: 100%;
        margin-bottom: 50px;
        border-radius: 20px;
        transition: all 350ms ease-in-out;
      }

      & span {
        display: block;
        order: 2;
        max-width: 400px;
        margin-right: 100px;
        font-size: 18px;
        font-weight: 600;
        color: $blackText;
        transition: all 350ms ease-in-out;
      }

      & p {
        order: 3;
        flex-grow: 1;
        font-size: 18px;
        color: $grayText;
        transition: all 350ms ease-in-out;
      }
    }
  }

  & .massage_masters-bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 100px;
    & .massage_masters-bottom__block {
      width: 50%;

      & h5 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        transition: all 350ms ease-in-out;
      }

      & p {
        max-width: 500px;
        color: $grayText;
        font-size: 18px;
        margin-bottom: 10px;
        transition: all 350ms ease-in-out;

        & span {
          font-weight: 400;
        }

        & br {
          display: none;
        }
        /*
                &:last-child {
                    max-width: 470px;
                } */
      }
      &:last-child p {
        max-width: 545px;
      }

      & img {
        border-radius: 10px;
        transition: all 350ms ease-in-out;
      }
    }

    &.bottom .massage_masters-bottom__block:last-child {
      max-width: 470px;
      margin-left: auto;
    }
  }

  & .block_test_drive-from {
    display: flex;
    background: #fbfbfb;
    border: 1px solid #b7b7b7;
    border-radius: 50px;
    align-items: center;
    margin-bottom: 80px;
    height: 518px;
    & .block_test_drive-from__block {
      width: 50%;

      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          transform: translate(10px, 30px);
        }
      }

      & > div {
        display: flex;

        & .call_back-checkbox {
          margin: 0 5px;
        }

        & p {
          font-size: 14px;
          color: $grayText;
          max-width: 300px;
          margin-bottom: 30px;
          margin-left: 5px;

          & a {
            color: $blackGray;
          }
        }
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        max-width: 370px;
        margin-left: 20px;
      }

      & h6 {
        font-size: 45px;
        color: $blackText;
        font-weight: 300;
        margin-bottom: 10px;
      }

      & p {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 16px;
        color: $grayText;
      }

      & input {
        outline: none;
      }

      & input.form-text {
        border: 1px solid #c5c3c6;
        color: #939699;
        border-radius: 10px;
        padding: 11px 17px;
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 18px;
        background: #fbfbfb;
        &::placeholder {
          color: #c5c3c6;
          font-weight: 300;
          font-size: 18px;
        }

        &:last-child {
          margin-bottom: 24px;
        }
      }
      & input.form-button {
        height: 44px;
        background: $blue;
        color: $white;
        font-weight: 600;
        border-radius: 10px;
        font-size: 18px;
        border: none;

        &:disabled {
          opacity: 0.3;
        }
      }
    }
  }
}

/* swiper */
.wrapper__aboutcompany {
  & .swiper-container-horizontal > .swiper-scrollbar {
    height: 20px;
    background: none;
    border-top: 1px solid $blue;
    border-radius: 0;
    bottom: -10px;
  }
  & .swiper-scrollbar-drag {
    width: 20px !important;
    height: 20px;
    border-radius: 50%;
    background-color: $blue;
    top: -11px;
  }
  & .swiper-slide {
    font-size: 36px;
    font-weight: 300;
    color: $grayText;
    text-align: center;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 340ms ease-in-out;
  }
  & .swiper-slide.swiper-slide-active {
    font-size: 144px;
    font-weight: 600;
    color: $blue;
  }
  $slide-width: 334px;
  & .swiper-slide {
    padding: 0 60px;
  }
  & .swiper-slide.swiper-slide-active {
    width: $slide-width;
    padding: 0 !important;
  }
  & .swiper-wrapper {
    align-items: center;
    height: 165px;
  }

  & .swiper-container {
    overflow: unset;
    margin-bottom: 54px;
    &::before {
      content: "";
      bottom: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background: $blue;
    }

    &::after {
      content: "";
      display: block;
      background: $blue;
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: -10px;
      border-radius: 50%;
      left: -50%;
      transform: translate(50%);
      left: 0;
      transform: translate(calc(25px + calc(#{$slide-width} / 2)));
      visibility: visible;
    }
  }

  & .swiper-prev,
  & .next-dis,
  & .swiper-next {
    height: 50px;
    width: 70px;
    background: #c4c4c4 url(../img/aboutcompany/arrow-swipper.svg) center no-repeat;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: $blue url(../img/aboutcompany/arrow-swipper.svg) center no-repeat;
    }
  }
  & .swiper-next,
  & .next-dis {
    margin-left: 30px;
    transform: rotate(180deg);
  }
  & .history_swiper_massager__wrapper {
    h5 {
      font-size: 60px;
      font-weight: 300;
      color: $blackText;
      max-width: 568px;
      margin-left: auto;
      margin-bottom: 57px;
      transition: all 340ms ease-in-out;

      & span {
        color: $blue;
        display: block;
      }
    }
    & .history_swiper_massager {
      display: flex;
      flex-wrap: wrap;

      & .history_swiper_massager__block {
        width: 35%;
        display: flex;

        &:last-child {
          width: 65%;
        }

        & .history_massager__bs {
          display: flex;
          width: 100%;
          justify-content: space-between;

          & .history_massager__b {
            & h4 {
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 20px;
              color: $blackText;
            }
            & img {
              margin-top: -20px;
            }
            & p {
              font-weight: 300;
              font-size: 16px;
              color: $grayText;
            }

            &:first-child {
              max-width: 375px;
            }
            &:last-child {
              height: 330px;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper__aboutcompany {
    & .main__block_aboutcompany {
      height: 630px;
    }
    & .aboutcompany__blocks {
      & .aboutcompany__block {
        & p {
          & span {
            font-weight: 300;
          }
        }

        &:first-child {
          padding-right: 15px;
        }
      }
    }
    & .short_info__blocks {
      & .short_info__block {
        & > span {
          font-size: 64px;
          & > span:last-child {
            font-size: 34px;
          }
        }
        & > p {
          font-size: 20px;
        }
      }
    }
    & .history_company__blocks .history_company__block {
      &:last-child {
        // padding-left: 15px;
      }

      &:last-child p {
        max-width: 590px;
        &:nth-child(3) {
          max-width: 560px;
          /* & br {
                    display: none;
                } */
        }
      }
    }
    & .history_swiper_massager__wrapper {
      & h5 {
        font-size: 50px;
      }
    }

    & .swiper-wrapper {
      height: 115px;
    }

    & .swiper-slide {
      font-size: 30px;
      padding: 0 30px;
    }
    & .swiper-slide.swiper-slide-active {
      font-size: 90px;
      font-weight: 300;
    }

    & .massage_masters-top {
      & h5 {
        font-size: 50px;
      }
    }

    & .massage_masters-top {
      & div {
        & span {
          margin-bottom: 40px;
        }

        & p {
          font-weight: 300;
        }
      }
    }

    & .massage_masters-bottom {
      & .massage_masters-bottom__block {
        & img {
          width: 100%;
        }

        & p {
          font-weight: 300;
        }
      }
    }
    & .block_test_drive-from {
      height: 440px;
      border-radius: 20px;
    }
    & .block_test_drive-from .block_test_drive-from__block:first-child img {
      width: 100%;
      margin: 0;
      transform: translate(10px, 10px);
    }
    & .block_test_drive-from .block_test_drive-from__block h6 {
      font-size: 30px;
      line-height: 1.1em;
      margin-bottom: 8px;
    }

    & .block_test_drive-from .block_test_drive-from__block input.form-text {
      font-size: 16px;
      font-weight: 300;

      &::placeholder {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 12px;
      }
    }
    & .block_test_drive-from .block_test_drive-from__block:last-child {
      max-width: 278px;
      margin-left: 0;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__result
    .how-work__result__block:first-child {
    max-width: 405px;
    flex-shrink: 0;
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__result
    .how-work__result__block:last-child
    img {
    width: 240px;
    transform: translate(-35px, 35px);
  }
  .wrapper__aboutcompany {
    & .main__block_aboutcompany {
      margin-bottom: 80px;

      & div {
        transform: translate(-50%, -85%);
        & img {
          height: 50px;
          padding-bottom: 30px;
          margin-bottom: 22px;
        }
        & span {
          font-size: 20px;
        }
      }
    }

    & .aboutcompany__blocks {
      margin-bottom: 80px;
      & .aboutcompany__block {
        width: 100%;

        & div {
          width: 100%;
          height: 382px;

          & img {
            transform: translate(0);
          }
        }
        & p {
          margin-bottom: 40px;
        }
      }
    }
    & .short_info__blocks {
      margin-bottom: 80px;
      text-align: center;
      & .short_info__block {
        padding: 31px 0px;
      }
    }

    & .history_company {
      margin-bottom: 80px;
      & .history_company__blocks {
        & .history_company__block {
          width: 100%;
          &:first-child {
            order: 2;
          }
          &:last-child {
            order: 1;
            padding: 0;
            margin-bottom: 40px;
          }

          & h2 {
            padding: 0;
            max-width: 500px;
          }

          & div {
            width: 100%;
            max-width: 100%;
            height: 482px;
            & img {
              transform: translate(0, 0);
              left: 0;
              position: relative;
              width: 100%;
            }
          }
        }
      }
    }

    & .history_massager {
      background: #131313;
      margin-bottom: 80px;
      & .history_massager__blocks {
        margin: 0 -40px;
        & .history_massager__block {
          width: 100%;
          & h3 {
            margin-top: 70px;
            max-width: 500px;
            margin-bottom: 30px;
          }
          & p {
            margin-bottom: 10px;
          }

          &:first-child {
            padding: 0 40px;
            margin-bottom: 55px;
          }

          & img {
            margin: 0;
          }

          &:last-child {
            padding: 0 40px;
            background: #131313 url(../img/aboutcompany/history-massager.png) top center no-repeat;
            background-position: -71px;
            margin-bottom: 100px;
          }
        }
      }

      & .histor_massager__bs {
        padding-bottom: 72px;
        & .histor_massager__b {
          & img {
            width: 135px;
            margin-bottom: 20px;
          }
        }
      }
    }

    & .history_swiper_massager__wrapper {
      & h5 {
        margin-left: 0;
        margin-bottom: 90px;
      }
      & .history_swiper_massager {
        margin-bottom: 80px;
        & .history_swiper_massager__block {
          width: 100% !important;

          &:first-child {
            margin-bottom: 46px;
          }

          & .history_massager__bs {
            align-items: center;
            & .history_massager__b {
              width: 50%;
              & img {
                height: 327px;
                margin: 0;
              }
            }
          }
        }
      }
    }
    & .massage_masters-top {
      & h5 {
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
      & div {
        padding-bottom: 40px;
        & span {
          order: 1;
        }
        & img {
          order: 2;
          margin-bottom: 40px;
        }
        & p {
          order: 3;
        }
      }
    }

    & .massage_masters-bottom {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 80px;

      & .massage_masters-bottom__block {
        width: 100%;
        & img {
          margin-bottom: 40px;
        }

        & p {
          margin-bottom: 20px;
          max-width: 560px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.bottom {
        flex-direction: column;

        & .massage_masters-bottom__block:last-child {
          margin-left: 0;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  $slide-width: 130px;
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__result
    .how-work__result__block:last-child
    img {
    width: 75%;
    transform: translate(0, 35px);
  }
  .wrapper__aboutcompany {
    & .main__block_aboutcompany {
      height: 580px;
      background-position-x: -88px;
    }
    & .block_test_drive-from {
      flex-direction: column;
      padding: 55px 35px;
      height: 100%;

      & .block_test_drive-from__block {
        width: 100%;
        & img {
          transform: translate(0) !important;
        }

        & input.form-text {
          margin-bottom: 12px;
        }
        & input.form-button {
          font-weight: 300;
        }
      }
    }

    & .aboutcompany__blocks {
      margin-bottom: 60px;
      & .aboutcompany__block {
        & h1 {
          font-size: 40px;
        }
      }
    }

    & .aboutcompany__blocks .aboutcompany__block div img {
      transform: translate(-140px, 0);
    }

    & .short_info__blocks {
      margin-bottom: 60px;
      & .short_info__block {
        padding: 16px 0;
        border-radius: 5px;
        & > span {
          font-size: 30px;
          & > span:last-child {
            font-size: 20px;
          }
        }

        & > p {
          font-size: 12px;
        }
      }
    }
    & .history_company {
      margin-bottom: 60px;
      & .history_company__blocks {
        & .history_company__block {
          & h2 {
            font-size: 40px;
            margin-bottom: 20px;
          }
          & p {
            margin-bottom: 20px;

            & br {
              display: none;
            }

            &:last-child {
              margin: 0;
            }
          }
          & div {
            height: auto;
            margin-bottom: 10px;

            & img {
              transform: translate(0);
            }
          }
        }
      }
    }

    & .history_massager {
      margin-bottom: 60px;
      & .history_massager__blocks {
        & .history_massager__block {
          margin-bottom: 60px;
          & h3 {
            margin-top: 60px;
            margin-bottom: 20px;
            font-size: 40px;
          }
          & p {
            margin-bottom: 20px;
          }

          &:last-child {
            background-position-x: -200px;
          }
        }
      }

      & .histor_massager__bs {
        & .histor_massager__b {
          width: 50%;
          margin-bottom: 30px;
        }
      }
    }
    & .history_swiper_massager__wrapper {
      & .history_swiper_massager {
        margin-bottom: 30px;
        & h5 {
          font-size: 40px;
          margin-bottom: 40px;
        }
      }
    }
    & .swiper-slide {
      font-size: 24px;
    }
    & .swiper-slide.swiper-slide-active {
      font-size: 60px;
    }
    & .swiper-slide.swiper-slide-active {
      width: $slide-width;
    }

    & .swiper-container {
      margin-bottom: 68px;
      &::after {
        transform: translate(calc(25px + calc(#{$slide-width} / 2)));
      }
    }
    & .history_swiper_massager__wrapper .history_swiper_massager .history_swiper_massager__block:first-child {
      margin-bottom: 38px;
    }

    & .swiper-prev,
    .wrapper__aboutcompany .next-dis,
    .wrapper__aboutcompany .swiper-next {
      margin-left: auto;
    }

    & .history_swiper_massager__wrapper .history_swiper_massager .history_swiper_massager__block .history_massager__bs {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      & .history_massager__b {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 30px;

        & img {
        }
      }
    }

    & .massage_masters-top {
      margin-bottom: 60px;
      & h5 {
        font-size: 40px;
        margin-bottom: 60px;
      }
      & div {
        padding-bottom: 30px;
        & span {
          margin-bottom: 30px;
        }
        & p {
          & br {
            display: none;
          }
        }
      }
    }

    & .block_test_drive-from {
      flex-direction: column;
      padding: 45px 35px;

      & .block_test_drive-from__block {
        width: 100%;

        & h6 {
          font-size: 25px;
        }
        & img {
          transform: translate(0) !important;
        }

        & input.form-text {
          margin-bottom: 12px;
        }
      }
    }
    & .block_test_drive-from .block_test_drive-from__block > div p {
      font-size: 12px;
    }
  }
  footer .footer__blocks_bottom img {
    height: 27px;
    &:first-child {
      height: 37px;
    }
  }
  .wrapper__aboutcompany .massage_masters-bottom {
    margin-bottom: 60px;
  }
  .massage_masters-bottom.bottom {
    margin-bottom: 80px;
  }
}
@media (max-width: 570px) {
  $slide-width: 130px;
  .wrapper__aboutcompany {
    & .aboutcompany__blocks {
      & .aboutcompany__block {
        & div {
          height: 286px;
          & img {
            transform: translate(-20px, 0);
          }
        }
      }
    }
    & .history_company .history_company__blocks .history_company__block p {
      & br {
        display: none;
      }
    }
    & .history_massager .history_massager__blocks .history_massager__block img {
      width: 295px;
    }
    & .history_massager .history_massager__blocks .history_massager__block:last-child {
      background-size: 155%;
      background-position-x: -10px;
    }
    & .history_massager .histor_massager__bs {
      padding-bottom: 52px;
    }
    & .swiper-slide.swiper-slide-active {
      margin: 0 20px;
    }

    & .swiper-container {
      margin-bottom: 68px;
      &::after {
        transform: translate(calc(50px + calc(#{$slide-width} / 2)));
      }
    }
    & .swiper-prev,
    & .next-dis,
    & .swiper-next {
      width: 66px;
      height: 46px;
    }
    & .massage_masters-top h5 {
      max-width: 340px;
    }
    & .massage_masters-top div span {
      margin-right: 0;
      max-width: 350px;
    }
  }
}
