@import "./color.scss";

.main_block {
  overflow: hidden;

  .swiper-container-pointer-events {
    z-index: 0;
  }

  & .slick-slide img {
    width: 100%;
  }
}

.slider_wrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}

.mekhanizmy_wrapper {
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  border-radius: 50px;
}

.mekhanizm_5D-AI_wrapper {
  max-width: 1420px;
  margin: 0 auto;
}
header {
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.35) 40.09%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 160px;
  z-index: 3;

  &.nograd {
    background: none;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 36px 0 0;

  & .header__logo {
    position: relative;
    & .sub__logo {
      width: 100%;
      font-size: 14px;
      color: $gray;
      text-align: center;
      y & span {
        color: $gray;
      }
    }
  }

  & .header__info {
    flex-grow: 1;

    & .heder__info_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 80px;
      margin-right: 22px;

      & .heder__info_block_menu {
        & ul {
          list-style: none;
          padding: 0;
          display: flex;
          margin: 0;

          & li {
            margin: 0 15px;
            position: relative;

            &:last-child {
              margin-bottom: 0 !important;
            }

            & a {
              font-size: 16px;
              color: $white;

              &.active {
                font-weight: 600;
                &::after {
                  display: block;
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 1px;
                  background: $white;
                  bottom: -7px;
                  left: 50%;
                  transform: translate(-50%, 0);
                  transition: all 50ms ease-in-out;
                }
              }

              &::after {
                display: block;
                position: absolute;
                content: "";
                width: 0;
                height: 1px;
                background: $white;
                bottom: -7px;
                left: 50%;
                transform: translate(-50%, 0);
                transition: all 50ms ease-in-out;
              }

              &:hover {
                &::after {
                  width: 100%;
                  transition: all 150ms ease-in-out;
                }
              }
            }
          }
        }
      }

      & .heder__info_block_contacts {
        & > a {
          font-size: 18px;
          color: $white;
          font-weight: 700;
        }
      }
    }
  }
  & .header__call_back-button {
    display: none;
  }
  & .header__call_back {
    color: $white;
    font-size: 16px;
    font-weight: 600;

    background: none;
    outline: none;
    border: 2px solid $blue;
    padding: 11px 25px;
    border-radius: 12px;
    transition: all 250ms ease-in-out;

    &.mainpage {
      background: $blue;
      color: $white;
      border: none;

      &:hover {
        background-color: $bluehover;
        border: none;
        transition: all 250ms ease-in-out;
      }

      &:active {
        background-color: #2a6fd0;
        border: none;
        transition: all 50ms ease-in-out;
      }
    }

    &:hover {
      background-color: $blue;
      transition: all 250ms ease-in-out;
    }

    &:active {
      background-color: $blueActive;
      border: 2px solid $blueActive;
      transition: all 50ms ease-in-out;
    }
  }
}

.main_block {
  & .slider_banner3 {
    height: 745px;
    background: linear-gradient(
        133deg,
        #0c0c0c 45%,
        transparent 58%,
        #0c0c0c 110%
      ),
      url(../img/slider/banner3.png) bottom no-repeat;
    background-size: contain;
    transition: all 100ms ease-in-out;
  }

  & .slider_text_block {
    position: absolute;
    left: 15px;
    top: 236px;
    z-index: 1;

    & .slider_title,
    & .slider_model {
      font-size: 64px;
      font-weight: 300;
      text-transform: uppercase;
    }

    & .slider_title {
      color: $white;
      transition: all 200ms ease-in-out;
    }
    & .slider_model {
      color: $blue;
      margin-bottom: 10px;
      transition: all 250ms ease-in-out;
    }

    & p {
      margin: 0;
      font-size: 22px;
      font-weight: 300;
      color: $white;
      margin-bottom: 30px;
      transition: all 400ms ease-in-out;
    }

    & button {
      width: 252px;
      height: 44px;
      border-radius: 12px;
      background: $blue;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      outline: none;
      border: none;
      transition: all 200ms ease-in-out;

      &:hover {
        background: $bluehover;
      }
    }
  }

  & .swiper-pagination {
    display: flex !important;
    bottom: 64px;
    left: 280px;

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      border: 1px solid $white;
      opacity: 1;
      background: none;

      &.swiper-pagination-bullet-active {
        background: $white;
      }
    }
  }
}

.slider_number {
  color: $white;
  font-size: 22px;
  position: absolute;
  bottom: 56px;
  left: 135px;

  &-active {
    font-size: 50px;
    font-weight: 300;
    line-height: 1em;
  }
}

/* Второй блок */
.description_block {
  background: url(../img/description_block-bg.png) center no-repeat;
  // box-shadow: inset 0 0 20px 20px rgba(12, 12, 12, 1);
  background-size: cover;
  background-size: contain;
  margin: 0 -40px;
  padding: 0 40px;
  color: $white;
  display: flex;
  margin-top: 105px;
  margin-bottom: 150px;

  & .description_block-massage-light_top {
    background: rgba(0, 108, 255, 0.23);
    mix-blend-mode: overlay;
    filter: blur(100px);
    position: absolute;
    width: 600px;
    height: 70%;
    top: 30%;
    z-index: 2;
    transition: all 200ms ease-in-out;
  }

  & .description_block-massage-light_bottom {
    position: absolute;
    background: rgba(142, 190, 255, 0.15);
    filter: blur(100px);
    width: 200px;
    height: 100px;
    border-radius: 50%;
    bottom: 0;
    z-index: 0;
    transition: all 450ms ease-in-out;
  }

  & .description_block-massage {
    height: 868px;
    flex-grow: 1;
    width: 48%;
  }
  & .description_block-massage img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transition: all 100ms ease-in-out;
  }
  & .description_block-texts {
    width: 52%;
    margin-top: 105px;
  }
  & .description_block-title {
    font-size: 60px;
    line-height: 1em;
    font-weight: 300;
    margin-bottom: 90px;
    transition: all 300ms ease-in-out;
  }
  & .description_block-title span {
    color: $blue;
  }

  & .description_block-description {
    font-size: 18px;
    font-weight: 300;
    margin: 0 auto;
    margin-bottom: 90px;
    max-width: 460px;
    text-indent: 2.2em;
    position: relative;
    z-index: 3;
    transition: all 300ms ease-in-out;
  }

  & .description_block-sub_title {
    font-size: 50px;
    font-weight: 300;
    font-style: italic;
    text-align: end;
    max-width: 470px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    transition: all 300ms ease-in-out;
  }
}
.video {
  position: relative;
  margin-bottom: 150px;

  & .vol_video {
    z-index: 1;
    position: absolute;
    height: 116px;
    width: 116px;
    background: url(../img/vol_mob.svg) center no-repeat;
    background-size: contain;
    border: 1px solid $white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.active {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.application {
  & .application_main_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: $white;
    margin-bottom: 150px;
  }

  & .application_main_block h4 {
    flex-grow: 1;
    width: 50%;
    font-size: 45px;
    font-weight: 300;
    transition: all 300ms ease-in-out;

    & span {
      color: $blue;
      transition: all 200ms ease-in-out;
    }
  }

  & .application_blocks {
    width: 50%;
  }

  & .application_blocks__row {
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 27px;
    }
  }

  & .application_block {
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    max-width: 268px;
    width: 100%;
    height: 204px;
    position: relative;

    &:nth-child(1) {
      transition: all 200ms ease-in-out;
    }

    &:nth-child(2) {
      transition: all 500ms ease-in-out;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      padding: 1px;
      background: linear-gradient(
        119.76deg,
        rgba(0, 108, 255, 0.63) -5.85%,
        rgba(0, 108, 255, 0.38) 21.26%,
        rgba(0, 108, 255, 0.21) 52.8%,
        rgba(0, 108, 255, 0.48) 108.49%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    & .application_block__icon {
      margin-bottom: 22px;
    }
    & p {
      font-size: 18px;
      margin: 0;
    }
  }
}

.us_company {
  color: $white;

  & .us_company_blocks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;

    & .us_company_block {
      width: 50%;

      & .us_company__title {
        font-size: 60px;
        font-weight: 300;
        margin-bottom: 25px;
        transition: all 500ms ease-in-out;
      }

      & p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        max-width: 500px;
        line-height: 1.3em;
        margin-bottom: 8px;
        transition: all 800ms ease-in-out;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      & .us_company_block__info {
        display: grid;
        grid-template-columns: 1fr 2fr;

        & .info__block {
          & span {
            font-size: 60px;
            font-weight: 300;
          }

          & p {
            font-size: 16px;
            color: $blue;
          }

          &:first-child {
            margin-bottom: 46px;
          }

          &:nth-child(1) {
            transition: all 400ms ease-in-out;
          }
          &:nth-child(2) {
            transition: all 600ms ease-in-out;
          }
          &:nth-child(3) {
            transition: all 800ms ease-in-out;
          }
          &:nth-child(4) {
            transition: all 1000ms ease-in-out;
          }
        }
      }
    }
  }

  & .us_company-bg {
    width: 100%;
    height: 369px;
    background: url(../img/us_company-bg.png) left no-repeat;
    background-size: contain;
    margin-bottom: 150px;
    transition: all 400ms ease-in-out;
  }
}

.advantage {
  & .advantage-bg {
    background: url(../img/advantage-bg.png) center no-repeat;
    color: $white;
  }

  & .advantage_block_top {
    height: 1174px;
  }

  & .advantge_title {
    font-size: 60px;
    font-weight: 300;
    line-height: 1em;
    max-width: 610px;
    text-indent: 45px;
    padding-top: 40px;
    margin-bottom: 42px;

    & span {
      transition: all 500ms ease-in-out;
      display: block;
      &:nth-child(1) {
        color: $blue;
      }
      &:nth-child(2) {
        text-indent: 0px;
      }
      &:nth-child(3) {
        text-indent: 66px;
      }
    }
  }
  & .advantage_sub_title__block {
    display: flex;
    align-items: center;

    & .advantage_sub_title {
      text-indent: -22px;
      margin-left: 22px;
      font-size: 18px;
      font-weight: 300;
      max-width: 325px;
      line-height: 1.4em;
      transition: all 700ms ease-in-out;
      margin-right: 50px;
    }
  }
  & .advantage_block_bottom {
    color: $white;
    margin-bottom: 150px;

    & .advantage_block_row {
      display: flex;

      & .advantage_block {
        width: 50%;
        transition: all 700ms ease-in-out;

        & h6 {
          margin: 0;
          font-size: 40px;
          font-weight: 300;
          margin-bottom: 20px;

          & span {
            color: $blue;
          }
        }

        & p {
          margin: 0;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.3em;
          max-width: 470px;
        }
      }
      &:first-child {
        margin-bottom: 116px;
      }
      &:first-child .advantage_block:last-child p {
        max-width: 450px;
      }

      &:last-child .advantage_block:first-child p {
        max-width: 420px;
      }

      &:last-child .advantage_block:last-child p {
        max-width: 430px;
      }
    }
  }
}

.mekhanizmy {
  & .mekhanizmy-bg {
    width: 100%;
    height: 660px;
    background: $blackBlue url(../img/mekhanizmy_bg.png) left no-repeat;
    border-radius: 50px;
    margin-bottom: 90px;
    color: $white;
    display: flex;
    align-items: center;

    & .mekhanizmy_wrapper_text {
      padding-left: 70px;
      max-width: 515px;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.3em;
      z-index: 1;

      & .mekhanizmy_title {
        font-size: 50px;
        font-weight: 300;
        line-height: 55px;
        margin-bottom: 30px;
        transition: all 250ms ease-in-out;

        & span {
          color: $blue;
        }
      }
      & .mekhanizmy_description {
        margin: 0;
        transition: all 300ms ease-in-out;
      }
    }
    & .mekhanizmy_massage {
      z-index: 1;
    }
    & .mekhanizmyMassage {
      background: url(../img/mekhanizmy_massage.png) right no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    & img {
      position: absolute;
      bottom: 0;
      right: -30px;
      z-index: 1;
      transition: all 1700ms;
    }
  }

  & .mekhanizmy-light {
    background: url(../img/mekhanizmy_light.png) right no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: all 3000ms ease-in-out;
  }

  & .mekhanizmy_scheme {
    background: url(../img/mekhanizmy_scheme.png) right no-repeat;
    width: 370px;
    height: 100%;
    height: 391px;
    position: absolute;
    top: 60px;
    right: 165px;
    z-index: 2;
    transition: all 2500ms ease-in-out;
  }

  & .mekhanizmy_5dai {
    background: url(../img/5D2.png) right no-repeat;
    width: 173px;
    height: 100%;
    z-index: 100;
    position: absolute;
    right: 255px;
    top: -70px;
    transition: all 3500ms ease-in-out;
  }
}

.mekhanizm_5D-AI {
  background: url(../img/mekhanizm_5D-AI_bg.png) -40px no-repeat;
  position: relative;
  margin-bottom: 150px;

  & .mekhanizm_5D-AI-light {
    position: absolute;
    background: url(../img/mekhanizm_5D-AI-light.png) -40px no-repeat;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 300ms ease-in-out;
  }

  & .mekhanizm_5D-AI__blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 1;
    position: relative;

    & .mekhanizm_5D-AI__block {
      width: 50%;
      color: $white;

      & img {
        margin-left: -15px;
        padding-bottom: 60px;
        transition: all 600ms ease-in-out;
      }

      & .mekhanizm_5D-AI__title {
        font-size: 60px;
        font-weight: 300;
        line-height: 1em;
        margin-bottom: 38px;
        transition: all 900ms ease-in-out;

        & span {
          color: $blue;
          display: block;
        }
      }
      & .mekhanizm_5D-AI__description {
        font-size: 18px;
        font-weight: 300;
        max-width: 500px;
        line-height: 1.3em;
        margin-bottom: 40px;
        transition: all 900ms ease-in-out;
      }
      & > div {
        & > span {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          line-height: 1.3em;
          font-size: 18px;
          font-weight: 300;
          max-width: 470px;
          transition: all 900ms ease-in-out;

          & > div {
            color: $blue;
            font-size: 24px;
            font-weight: 300;
            min-width: 30px;
            height: 30px;
            position: relative;
            margin-right: 18px;
            background: url(../img/number1.svg) left top no-repeat;
          }
          &:last-child {
            & > div {
              background: url(../img/number2.svg) center no-repeat;
            }
          }
        }
      }
    }
  }
}

.mekhanizm_coverage {
  margin-bottom: 150px;

  & .mekhanizm_coverage__blocks {
    display: flex;
    background: url(../img/mekhanizmy_bg.png) right no-repeat;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & .mekhanizm_coverage__block {
      grid-area: 1 / 1 / 2 / 3;
      color: $white;
      position: relative;

      & img {
        display: flex;
        margin: auto;
      }

      & .mekhanizm_coverage__light {
        height: 872px;
        width: 452px;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 0;
        left: 25px;
        z-index: 0;
        background: rgba(142, 190, 255, 0.1);
        filter: blur(100px);
        transform: rotate(-33deg);
      }

      & .mekhanizm_coverage__title {
        font-size: 60px;
        font-weight: 300;
        line-height: 1em;
        margin-bottom: 38px;
        transition: all 400ms ease-in-out;

        & span {
          color: $blue;
          display: block;
        }
      }

      & .mekhanizm_coverage__description {
        font-size: 18px;
        font-weight: 300;
        max-width: 550px;
        text-indent: 5.6em;
        margin-bottom: 115px;
        transition: all 500ms ease-in-out;
      }
    }
    & .mekhanizm_coverage_info__blocks {
      display: flex;
      flex-wrap: wrap;
      grid-area: 2 / 1 / 3 / 2;

      & .mekhanizm_coverage_info__block {
        margin-bottom: 27px;
        position: relative;
        transition: all 500ms ease-in-out;

        & > div {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 108, 255, 0.5);
          backdrop-filter: blur(15px);
          border-radius: 20px 0px 0px 0px;
          width: 92px;
          height: 45px;
        }

        & span {
          position: absolute;
          color: $white;
          top: 9px;
          left: 14px;
          font-size: 24px;
          font-weight: 300;
        }

        &:nth-child(1) {
          margin-right: 50px;
        }

        &:nth-child(3) {
          margin-right: 50px;
        }
      }
    }
    & .mekhanizm_coverage__block-img {
      grid-area: 1 / 2 / 3 / 3;
      text-align: center;

      & img {
        transition: all 100ms ease-in-out;
      }
    }
  }
}

.modes_mekhanizm {
  color: $white;

  &__top_blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #ffffff40;
    border-bottom: 1px solid #ffffff40;
    padding: 61px 0;

    & .modes_mekhanizm__top_block {
      width: 50%;
      font-size: 60px;
      font-weight: 300;
      line-height: 1em;

      & p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.3em;
        max-width: 440px;
      }

      & > span {
        color: $blue;
        display: block;
      }
    }
  }

  &__bottom_blocks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 55px 0;

    .modes_mekhanizm__bottom_block {
      width: 50%;
      margin-bottom: 60px;
      transition: all 400ms ease-in-out;

      &:nth-child(5),
      &:nth-child(6) {
        margin-bottom: 0;
      }

      & .modes_mekhanizm__bottom_block__title {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      & p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        max-width: 375px;
      }
    }
  }
}

.gravity__wrapper {
  background: linear-gradient(16.71deg, #0c0c0c 14%, rgba(12, 12, 12, 0) 22%),
    linear-gradient(
      340deg,
      #0c0c0c 9.38%,
      rgba(0, 0, 0, 0) 41%,
      rgba(12, 12, 12, 0) 41%
    ),
    linear-gradient(176.87deg, #0c0c0c 19%, rgba(12, 12, 12, 0) 23%),
    url(../img/gravity-bg.png) center no-repeat;
  height: 1480px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: inset 0px 0px 20px 20px rgba(12, 12, 12, 1);
  transition: all 400ms ease-in-out;

  & h4 {
    font-size: 300px;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 52%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background: linear-gradient(180deg, #ffffff 33%, #ffffff82 72%, #8aa9d6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & .gravity_massager {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 57%;
    transform: translate(-50%, 0);
  }

  & .gravity_massagerLight {
    mix-blend-mode: overlay;
    background: linear-gradient(
      0deg,
      #ffffff00 0%,
      #0c0c0c 27%,
      #2386d7 50%,
      rgba(0, 108, 255, 0.7) 83%,
      #ffffff00 99%
    );
    position: absolute;
    bottom: 0;
    height: 792px;
    width: 100%;
    z-index: 2;
  }
  & .gravity__main_blocks {
    display: flex;
    flex-wrap: wrap;

    & .gravity__main_block {
      width: 50%;
      padding-top: 116px;

      & .gravity_astronaut {
        position: absolute;
        top: 283px;
        left: 283px;
        animation: astronaut 15s infinite reverse ease-in-out;
        transition: all 400ms ease-in-out;
      }
      & h6 {
        font-size: 60px;
        font-weight: 300;
        color: $white;
        line-height: 1em;
        margin-bottom: 30px;
        transition: all 400ms ease-in-out;

        & span {
          color: $blue;
          display: block;
        }
      }

      & p {
        font-size: 18px;
        font-weight: 300;
        color: $white;
        margin: 0;
        max-width: 470px;
        line-height: 1.4em;
        margin-bottom: 40px;
        transition: all 400ms ease-in-out;
      }

      & button {
        width: 275px;
      }
    }
  }
}

@keyframes astronaut {
  from {
    transform: translate(15px, 9px);
  }

  to {
    transform: translate(15px, 9px);
  }

  25% {
    transform: translate(5px, 2px);
  }

  55% {
    transform: translate(8px, 4px);
  }

  75% {
    transform: translate(2px, 7px);
  }
}

.catalog_fujiiryoki {
  padding: 58px 0 80px 0;
  margin-bottom: 150px;

  & h4 {
    font-size: 60px;
    font-weight: 300;
    color: $white;
    line-height: 1em;
    margin-bottom: 65px;
    text-align: center;

    & span {
      color: $blue;
      display: block;
    }
  }

  & .catalog__fujiiryoki__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    & .catalog__fujiiryoki__item {
      width: 470px;
      text-align: center;
      position: relative;
      padding: 46px 47px 60px 47px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px;
        padding: 1px;
        background: linear-gradient(
          119.76deg,
          rgba(0, 108, 255, 0.63) -5.85%,
          rgba(0, 108, 255, 0.38) 21.26%,
          rgba(0, 108, 255, 0.21) 52.8%,
          rgba(0, 108, 255, 0.48) 108.49%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }

      & > div {
        font-size: 12px;
        color: $grayText;
        margin-bottom: 30px;
      }
      &:hover::before {
        -webkit-mask-composite: destination-over;
        background: linear-gradient(
          54deg,
          rgba(0, 108, 255, 0.39) -5.85%,
          rgba(0, 108, 255, 0.15) 21.26%,
          rgba(0, 108, 255, 0.04) 52.8%,
          rgba(0, 108, 255, 0.39) 108.49%
        );
      }

      & img {
        width: 100%;
        margin-bottom: 29px;
        position: relative;
        z-index: 1;
      }
      & h6 {
        color: $grayNew;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }

      & > span {
        display: block;
        color: $white;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 8px;
        position: relative;
        z-index: 1;

        & > span {
          color: #939699;
          margin-left: 15px;
          font-weight: 300;
          font-size: 18px;
          text-decoration: line-through;
        }
      }
    }

    & a {
      color: $blue;
      text-decoration: none;
      width: 165px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $blue;
      border-radius: 42px;
      margin: 0 auto;
      background-position: 123px;
      transition: all 200ms ease-in-out;
      position: relative;
      z-index: 1;
      cursor: pointer;
      & span {
        display: block;
        margin-left: 10px;
        width: 8px;
        height: 10px;
        background: url(../img/arrow-blue.svg) center no-repeat;
        position: relative;
        transform: translateX(0px);
        transition: all 200ms ease-in-out;
      }

      &:active span {
        background-position: 130px;
        background: url(../img/arrow-white.svg) center no-repeat;
      }

      &:hover {
        background: $blue;
        color: $white;

        & span {
          background: url(../img/arrow-white.svg) center no-repeat;
          transform: translateX(10px);
        }
      }
      &:active {
        background: $blueActive;
        border: 1px solid $blueActive;

        & span {
          background: url(../img/arrow-white.svg) center no-repeat;
          background-position: 130px;
        }
      }
    }
  }
}

.quality_reliability {
  color: $white;
  margin-bottom: 200px;

  & .quality_reliability__blocks {
    background: #22262f;
    border-radius: 50px;
    height: 733px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;

    & .quality_reliability__blocks_light {
      position: absolute;
      width: 800px;
      height: 120%;
      background: radial-gradient(#006cff 0%, transparent 70%);
      background-position: -87px;
      background-repeat: no-repeat;
      transition: all 550ms ease-in-out;
    }

    & .quality_reliability_block {
      width: 50%;
      position: relative;

      & .quality_reliability_massager {
        left: -30px;
        bottom: -43px;
        position: absolute;
        transition: all 350ms ease-in-out;
      }
      & .quality_reliability_massager__info {
        position: absolute;
        bottom: 208px;
        left: 52%;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        transition: all 250ms ease-in-out;
      }
      & .quality_reliability_massager__info2 {
        position: absolute;
        left: 78%;
        bottom: 85px;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        transition: all 250ms ease-in-out;
      }

      & h4 {
        font-size: 50px;
        font-weight: 300;
        margin-top: 80px;
        margin-bottom: 30px;
        line-height: 1em;
        transition: all 250ms ease-in-out;
      }

      & p {
        font-size: 18px;
        font-weight: 300;
        max-width: 470px;
        margin: 0;
        line-height: 1.4em;
        flex-shrink: 0;
        margin-bottom: 22px;
        transition: all 250ms ease-in-out;
      }

      & .quality_reliability_massager__info,
      & .quality_reliability_massager__info2 {
        & p {
          margin-left: 24px;
        }
      }
    }
  }
}

.shiatsu_acupressure {
  color: $white;
  margin-bottom: 100px;

  &__top_blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #ffffff40;
    border-bottom: 1px solid #ffffff40;
    padding: 61px 0;

    & .shiatsu_acupressure__top_block {
      width: 50%;
      font-size: 60px;
      font-weight: 300;
      line-height: 1em;
      transition: all 350ms ease-in-out;

      & span {
        color: $blue;
      }

      & p {
        font-size: 18px;
        font-weight: 300;
        margin: 0;
        line-height: 1.3em;
        max-width: 450px;
        transition: all 350ms ease-in-out;
      }
    }
  }

  &__bottom_blocks {
    display: flex;
    flex-wrap: wrap;

    & .shiatsu_acupressure__bottom_block {
      width: 50%;
      position: relative;

      & > p {
        max-width: 460px;
        margin: 0;
        font-size: 18px;
        margin-top: 50px;
        margin-bottom: 60px;
        transition: all 350ms ease-in-out;
      }

      & .shiatsu_acupressure__demonstration_main {
        margin-left: -70px;
        transition: all 350ms ease-in-out;
      }

      & .shiatsu_acupressure__demonstration_main-light {
        background: rgba(142, 190, 255, 0.1);
        filter: blur(100px);
        transform: rotate(32.12deg);
        position: absolute;
        top: 0;
        width: 454px;
        height: 750px;
        transition: all 550ms ease-in-out;
      }
    }

    & .shiatsu_acupressure__bottom_demonstration_blocks {
      display: flex;
      max-width: 500px;
      justify-content: space-between;

      & .shiatsu_acupressure__bottom_demonstration_block img {
        margin-bottom: 14px;
        transition: all 350ms ease-in-out;
      }

      & .shiatsu_acupressure__bottom_demonstration_block p {
        max-width: 200px;
        font-size: 18px;
        margin: 0;
        transition: all 450ms ease-in-out;
      }
    }
  }
}

.stretching_fitness_trainer {
  color: $white;
  background: $blackGray;
  position: relative;
  margin-bottom: 150px;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 186px;
    background: $black;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 55%);
  }

  &__wrapper {
    max-width: 1316px;
    max-width: 1444px;
    margin: 0 auto;
    position: relative;

    & .stretching_fitness_trainer__blocks {
      display: flex;
      flex-wrap: wrap;
      max-width: 1170px;
      margin: 0 auto;
      height: 878px;
      align-items: center;

      & .stretching_fitness_trainer__block {
        width: 50%;

        &:last-child {
          align-self: flex-start;
        }
        & h4 {
          font-size: 60px;
          font-weight: 300;
          line-height: 1em;
          max-width: 470px;
          margin-bottom: 42px;
          transition: all 350ms ease-in-out;

          & span {
            color: $blue;
          }
        }

        & p {
          margin: 0;
          font-size: 18px;
          font-weight: 300;
          line-height: 1.3em;
          transition: all 350ms ease-in-out;
        }

        & .stretching_fitness_trainer-man {
          position: absolute;
          right: -130px;
          transition: all 350ms ease-in-out;
        }
        & .stretching_fitness_trainer_light {
          position: absolute;
          right: -200px;
          transition: all 750ms ease-in-out;
        }
      }
    }
    & .stretching_fitness_trainer-who {
      position: absolute;
      bottom: 82px;
      left: 50%;
      transform: translate(-50%, 0);

      & h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        max-width: 368px;
        text-align: left;
        transition: all 350ms ease-in-out;
      }

      & p {
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        max-width: 368px;
        transition: all 350ms ease-in-out;
      }
    }
  }
}

.full_body_stretch {
  color: $white;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  overflow-y: hidden;
  margin-bottom: 50px;

  & .main__button {
    width: 350px;
    margin: 0 auto;
    display: none;
  }

  & .full_body_stretch_bg {
    position: absolute;
    top: 140px;
    transition: all 550ms ease-in-out;
  }

  &__top_blocks {
    display: flex;
    flex-wrap: wrap;
    padding: 61px 0;
    border-top: 1px solid #ffffff40;
    border-bottom: 1px solid #ffffff40;

    & .full_body_stretch__top_block {
      width: 50%;
      font-size: 60px;
      line-height: 1em;
      transition: all 350ms ease-in-out;

      & span {
        color: $blue;
      }

      & p {
        font-size: 18px;
        font-weight: 300;
        margin: 0;
        line-height: 1.3em;
      }
    }
  }

  &__bottom_blocks {
    display: flex;
    flex-wrap: wrap;
    margin-top: 114px;

    & .full_body_stretch__bottom_block {
      width: 50%;

      & .full_body_stretch_massager {
        position: relative;
        left: -125px;
        top: -75px;
        transition: all 350ms ease-in-out;
      }
      & .full_body_stretch__bottom_block-item {
        display: flex;
        margin-bottom: 58px;
        transition: all 350ms ease-in-out;

        &:last-child {
          margin-bottom: 0px;
        }

        & img {
          margin-right: 30px;
          align-self: self-start;
        }

        & h6 {
          font-size: 18px;
          font-weight: 600;
          display: block;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        & p {
          margin: 0;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.3em;
        }
      }
    }
  }
}

.infrared_heating {
  color: $white;
  margin-bottom: 150px;

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1170px;
    height: 630px;
    margin: 0 auto;
    background: $blackGray;
    border-radius: 50px;
    position: relative;
    overflow: hidden;

    & .infrared_heating__block {
      width: 50%;

      & img {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        transition: all 350ms ease-in-out;
      }

      & > div {
        z-index: 1;
        position: relative;
        max-width: 470px;

        & h4 {
          font-size: 50px;
          font-weight: 300;
          line-height: 1em;
          margin-bottom: 30px;
          transition: all 350ms ease-in-out;

          & span {
            color: $blue;
            display: block;
          }
        }

        & > p {
          margin: 0;
          font-size: 18px;
          font-weight: 300;
          margin-bottom: 60px;
          line-height: 1.3em;
          transition: all 350ms ease-in-out;
        }

        & div > p {
          margin: 0;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.3em;
          padding: 26px 30px;
          border-radius: 20px;
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 15px;
            padding: 1px;
            background: linear-gradient(
              165deg,
              rgba(0, 108, 255, 0.63) -5.85%,
              rgba(0, 108, 255, 0.38) 21.26%,
              rgba(0, 108, 255, 0.9) 52.8%,
              rgba(0, 108, 255, 0.48) 108.49%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }
        }
      }
    }
  }
}

.manage_massage_easily {
  color: $white;
  height: 746px;
  max-width: 1440px;
  margin: 0 auto;
  background: $blackBlue url(../img/manage_massage_easily-bg.png) right
    no-repeat;
  border-radius: 80px 80px 0 0;
  position: relative;
  padding: 98px 134px;
  box-sizing: border-box;
  margin-bottom: 150px;
  overflow: hidden;

  &__blocks {
    display: flex;
    flex-wrap: wrap;

    & .manage_massage_easily__block {
      width: 50%;

      & h4 {
        font-size: 60px;
        font-weight: 300;
        margin-bottom: 38px;
        line-height: 1em;
        transition: all 350ms ease-in-out;

        & span {
          color: $blue;
        }
      }

      & .manage_massage_easily__block__bottom-main {
        margin-bottom: 50px;
      }
      & button {
        width: 350px;
      }

      & > p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        max-width: 470px;
        line-height: 1.3em;
        margin-bottom: 65px;
        transition: all 350ms ease-in-out;
      }
      & .manage_massage_easily__block__bottom {
        transition: all 350ms ease-in-out;
      }

      & .manage_massage_easily_main {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90%;
        z-index: 2;
        transition: all 350ms ease-in-out;
      }

      &__bottom {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        & > div {
          height: 62px;
          width: 62px;
          border: 2px solid $blue;
          flex-shrink: 0;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          display: flex;
          margin-right: 20px;
        }

        & p {
          margin: 0;
          line-height: 1.3em;
        }
      }
    }

    & .manage_massage_easily_main-light {
      position: absolute;
      width: 798px;
      height: 117%;
      background: radial-gradient(#006cff94 12%, transparent 72%);
      background-repeat: no-repeat;
      top: -36px;
      right: -59px;
      transform: rotate(338deg);
      transition: all 550ms ease-in-out;
    }
  }
}

.chair_colors {
  color: $white;
  margin-bottom: 150px;

  &__block__main_block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & h4 {
      font-size: 60px;
      line-height: 1em;
      font-weight: 300;

      & span {
        color: $blue;
      }
    }

    & .chair_colors__block-title,
    & .chair_colors__block-color {
      width: 50%;
      margin-bottom: 58px;
      transition: all 550ms ease-in-out;
    }

    & .chair_colors__block-color {
      display: flex;
      justify-content: right;
      align-items: center;

      & div {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        position: relative;
        margin: 0 15px;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:last-child {
          transition: all 350ms ease-in-out;
        }

        & span {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:nth-child(1) span {
          background: #1e1e1e;
        }
        &:nth-child(2) span {
          background: #cfc2ba;
        }
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          transition: all 250ms ease-in-out;
        }
        &.chair_colors__block-color-acrive::before {
          position: absolute;
          content: "";
          display: block;
          width: 110px;
          height: 110px;
          border-radius: 50%;
          transition: all 250ms ease-in-out;
          border: 2px solid $blue;
        }
      }
    }

    & .chair_colors__block-photo {
      width: 100%;
      border-radius: 50px;
      overflow: hidden;
      width: 100%;
      height: 650px;
      position: relative;

      & img {
        position: absolute;
        border-radius: 50px;
        opacity: 0;
        transition: all 250ms ease-in-out;
      }

      & .chair_colors__photo-active {
        z-index: 1;
        opacity: 1;
        transition: all 250ms ease-in-out;
      }
    }
  }
}

.flawless_build_quality {
  background: linear-gradient(
      42deg,
      rgba(12, 12, 12, 1) 0%,
      rgba(9, 9, 121, 0) 35%
    ),
    url(../img/flawless_build_quality-bg.png) center no-repeat;
  background-size: contain;
  color: $white;
  height: 891px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 90px;

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    padding-top: 124px;

    & .flawless_build_quality__block {
      width: 50%;

      & .flawless_build_quality_massagerLight {
        position: absolute;
        left: -180px;
        top: -200px;
        z-index: -1;
        transition: all 550ms ease-in-out;
      }

      & .flawless_build_quality_massage {
        position: relative;
        z-index: 1;
        left: -150px;
        top: 40px;
        transition: all 350ms ease-in-out;
      }

      & h4 {
        font-size: 60px;
        line-height: 1em;
        font-weight: 300;
        margin-left: 64px;
        margin-bottom: 60px;

        & span {
          color: $blue;
          margin-left: -64px;
          display: block;
        }
      }
      & p {
        font-size: 18px;
        line-height: 1.3em;
        font-weight: 300;
        margin-left: 200px;
        transition: all 650ms ease-in-out;
      }
    }
  }
}

.delivery_assembly {
  color: $white;
  margin-bottom: 200px;

  & h4 {
    font-weight: 300;
    font-size: 45px;
    text-align: center;
    margin-bottom: 24px;
    transition: all 250ms ease-in-out;
  }

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    & .delivery_assembly__block {
      width: 33.333%;

      &:nth-child(1) {
        transition: all 250ms ease-in-out;
      }
      &:nth-child(2) {
        transition: all 450ms ease-in-out;
      }
      &:nth-child(3) {
        transition: all 650ms ease-in-out;
      }

      & img {
        margin-bottom: 20px;
      }

      & h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      & p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.2em;
      }
    }
  }
}

.technical_specifications {
  & .technical_specifications__title {
    font-size: 60px;
    font-weight: 400;
    color: $white;
    text-align: center;
    margin-bottom: 60px;
    transition: all 250ms ease-in-out;
    & span {
      color: $blue;
      display: block;

      & br {
        display: none;
      }
    }
  }
  & .technical_specifications__blocks {
    & .technical_specifications__tables {
      display: flex;
      margin: 0 -15px;
      margin-bottom: 130px;
      & div {
        width: 50%;
        padding: 0 15px;
        & table {
          color: $white;
          table-layout: fixed;
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #4e5155;
          transition: all 250ms ease-in-out;
          & td {
            border: 1px solid #4e5155;
            padding: 11px 10px 11px 27px;
            font-size: 16px;
            font-weight: 300;

            &:first-child {
              color: #939699;
              padding: 11px 10px 11px 24px;
              vertical-align: text-top;
              width: 285px;
            }
          }
        }
      }
    }

    & .technical_specifications-images {
      & .technical_specifications-images-title {
        color: $white;
        font-size: 45px;
        font-weight: 400;
        margin-bottom: 50px;
        text-align: center;
        transition: all 250ms ease-in-out;
      }
      .technical_specifications-images__blocks {
        & img {
          width: 100%;
          transition: all 250ms ease-in-out;
        }
        .technical_specifications-images__block {
          display: none;
        }
      }
    }
  }
}

.call_back {
  max-width: 1440px;
  background: url(../img/call_back-bg.png) no-repeat top right;
  background-size: 1100px;
  background-position-y: 20px;
  height: 720px;
  margin: 0 auto;
  color: $white;

  & h6 {
    font-size: 45px;
    font-weight: 300;
    margin-bottom: 8px;
    padding-top: 240px;
  }

  &-form {
    max-width: 360px;

    & > .call_back__wrapper {
      max-width: 330px;
      display: flex;
      flex-direction: column;

      & p {
        font-size: 16px;
        font-weight: 300;
        margin: 0;
        margin-bottom: 17px;
      }

      & .call_back-checkbox {
        margin: 5px;
      }

      & .call_back-input {
        background: none;
        outline: none;
        border: 1px solid #c5c3c6;
        border-radius: 10px;
        height: 44px;
        margin-bottom: 9px;
        padding: 13px;
        box-sizing: border-box;
        color: $white;
        ::placeholder {
          color: $white;
        }
      }
      & > div {
        display: flex;
        margin-top: 3px;
        margin-bottom: 30px;

        & p {
          margin: 0;
          color: #6f7072;
          margin-left: 14px;
          font-size: 12px;
          font-weight: 300;

          & a {
            color: #939699;

            &:hover {
              color: $white;
            }
          }
        }
      }
    }

    & .call_back-button {
      width: 321px;
      height: 44px;
      background: $blue;
      color: $white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      cursor: pointer;
      border: none;

      &:disabled {
        opacity: 0.4;
      }
      &:hover {
        background: $bluehover;
      }
    }
  }
}

footer {
  background: $blackBlue;
  z-index: 0;

  & .footer__blocks_top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    color: $white;
    padding-top: 50px;
    margin-bottom: 50px;

    & .footer__block {
      width: 100%;

      &__logo {
        & .sub__logo {
          font-size: 14px;
          max-width: 159px;
          color: #8f8f8f;
          text-align: center;
          margin-bottom: 40px;
        }
      }

      & h6 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;

        & a {
          color: $white;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 20px;
          &:hover {
            color: #8f8f8f;
          }
        }
      }

      & a {
        color: $grayNew;
        font-size: 18px;
        font-weight: 300;
        text-decoration: none;
        display: block;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
          color: $white;
        }
      }

      & p {
        margin: 0;
        color: $grayNew;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }

      & .footer__block__social {
        display: flex;

        & a {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: block;
          margin-right: 27px;

          &:nth-child(1) {
            background: url(../img/whatsapp.svg) center no-repeat;
          }

          &:nth-child(2) {
            background: url(../img/viber.svg) center no-repeat;
          }

          &:nth-child(3) {
            background: url(../img/telegram.svg) center no-repeat;
            margin: 0;
          }
        }
      }

      .footer__block__contact {
        & .footer__block__contact-phone {
          background: url(../img/phone.svg) left top no-repeat;
          display: block;
          padding-left: 40px;
          margin-bottom: 15px;

          & p {
            font-size: 14px;
            font-weight: 300;
          }

          & a {
            font-weight: 700;
            font-size: 18px;
            color: $white;
            margin-bottom: 2px;
          }
        }

        & .footer__block__contact-email {
          background: url(../img/mail.svg) left top no-repeat;
          display: block;
          padding-left: 40px;
          margin-bottom: 50px;

          & a {
            border-bottom: 1px dashed;
            display: inline-block;
            margin: 0;
            font-size: 16px;
            font-weight: 300;
          }
        }
        & button {
          color: $white;
          background: $blue;
          font-size: 16px;
          font-weight: 600;
          width: 190px;
          height: 44px;
          border-radius: 12px;
          border: none;
          outline: none;

          &:hover {
            background: $bluehover;
          }
        }
      }
    }
  }

  .footer__blocks_bottom {
    border-top: 1px solid rgba(110, 110, 115, 0.8);
    color: $grayNew;
    filter: grayscale(100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    padding: 33px 0;
    & p {
      margin: 0;
    }
    & a {
      color: $grayNew;
      font-size: 16px;
      font-weight: 300;

      &:hover {
        color: $white;
      }
    }

    & img {
      margin: 0 11px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
body.hide {
  overflow: hidden;
}
/* Media */
@media (min-width: 1441px) {
  .manage_massage_easily {
    padding: 60px 70px;
    max-width: 1170px;
    height: 676px;
    border-radius: 50px;

    &_main {
      height: 660px;
    }
  }

  .flawless_build_quality {
    height: 834px;
  }
}

@media (max-width: 1400px) {
  .quality_reliability
    .quality_reliability__blocks
    .quality_reliability_block
    .quality_reliability_massager {
    left: -220px;
    bottom: -150px;
    position: absolute;
    width: 800px;
  }
}

@media (max-width: 1300px) {
  .manage_massage_easily {
    padding: 98px 40px;
  }
}
@media (min-width: 1201px) {
  .heder__info_block_contacts__bottom {
    display: none;
  }
}
@media (max-width: 1200px) {
  .video {
    margin-bottom: 100px;
  }
  .main_block .slider_banner3 {
    height: 630px;
  }

  .main_block {
    & .mekhanizm_5D-AI {
      & .mekhanizm_5D-AI__blocks {
        & .mekhanizm_5D-AI__block {
          & .mekhanizm_5D-AI__title {
            font-size: 50px;

            & span {
              display: block;
            }
          }
        }
      }
    }
  }
  .manage_massage_easily__blocks .manage_massage_easily__block .main__button {
    display: none;
  }
  .full_body_stretch .main__button {
    display: block;
  }
  .header {
    & .header__logo .sub__logo {
      font-size: 13px;
    }
    & .header__info {
      overflow-y: scroll;
      &::-webkit-scrollbar {
      }
      height: 100%;
      & .heder__info_block {
        flex-direction: column;
        margin: 0;
        margin-top: 18%;
        justify-content: center;

        &::-webkit-scrollbar {
          width: 0;
          background: none;
        }
        & .heder__info_block_menu {
          & ul {
            flex-wrap: wrap;
            justify-content: center;
            & li {
              width: 100%;
              text-align: center;
              margin: 23px 0;
              &:first-child {
                margin-top: 0;
              }
              & a {
                font-size: 40px;
                &:hover {
                  &::after {
                    display: none;
                  }
                }
              }
              & a.active::after {
                display: none;
              }
            }
          }
        }
      }
      & .heder__info_block_contacts {
        & > a {
          display: none;
        }
      }

      & .heder__info_block_contacts__bottom {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 120px;

        & .header_menu__top {
          position: absolute;
          padding: 36px 40px 0;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & .menu-close {
            width: 33px;
            height: 33px;
            background: url(../img/manu-close.svg) center no-repeat;
          }
        }

        & h6 {
          font-size: 40px;
          font-weight: 300;
          color: $white;
          margin-bottom: 24px;
        }
        & > a {
          font-size: 36px;
          font-weight: 700;
          color: $white;
          line-height: 1em;
          margin-bottom: 4px;
        }
        & span {
          font-size: 22px;
          font-weight: 300;
          color: $grayText;
          margin-bottom: 20px;
        }

        & .heder__info_block_contacts__bottom-social {
          display: flex;
          max-width: 250px;
          margin: 30px auto 0;

          & a {
            width: 46px;
            height: 46px;
            display: block;
            border-radius: 50%;
            margin: 0 15px;

            &:nth-child(1) {
              background: url(../img/whatsapp.svg) center no-repeat;
              background-size: cover;
            }
            &:nth-child(2) {
              background: url(../img/viber.svg) center no-repeat;
              background-size: cover;
            }
            &:nth-child(3) {
              background: url(../img/telegram.svg) center no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }
  .full_body_stretch__bottom_blocks {
    margin-top: 50px;
  }
  .catalog_fujiiryoki .catalog__fujiiryoki__items .catalog__fujiiryoki__item {
    & > span {
      & > span {
        font-size: 18px;
      }
    }
  }

  .description {
    & .description_block {
      margin-bottom: 50px;
      margin-top: 90px;
      & .description_block-massage-light_top {
        width: 450px;
        background: rgba(0, 108, 255, 0.19);
        filter: blu1(200px);
      }
    }
  }

  .header__info {
    display: none;
  }
  .header__info {
    // align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background: $black;
    min-height: 100vh;
    z-index: 1000;
    width: 100%;
  }
  .header__info.active {
    display: flex;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    justify-content: center;
    bottom: 25px !important;
  }

  .slider_number {
    left: 40px;
    bottom: 20px;
  }

  .main_block .slider_text_block {
    top: 275px;
    left: 40px;

    & .slider_title,
    & .slider_model {
      font-size: 50px;
    }

    & p {
      font-size: 18px;
    }
  }

  .header__right_blocks {
    display: flex;
    align-items: center;
    & .burger_menu {
      margin-left: 33px;
      width: 42px;
      height: 44px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      & span {
        height: 4px;
        width: 100%;
        background: $white;
        border-radius: 2px;
        display: block;

        &:nth-child(2) {
          width: 80%;
        }
        &:nth-child(3) {
          width: 60%;
        }
      }
    }
  }

  .description {
    & .description_block .description_block-massage {
      width: 100%;
    }
    & .description_block .description_block-texts {
      width: 100%;
      margin-top: 0;
    }

    & .description_block .description_block-title {
      font-size: 50px;
      margin-bottom: 50px;
      float: right;
      width: 510px;
    }

    & .description_block .description_block-description {
      font-size: 18px;
      margin-bottom: 45px;
      float: right;
    }

    & .description_block .description_block-sub_title {
      font-size: 30px;
      width: 300px;
      float: right;
    }
  }

  .application .application_main_block h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 35px;

    & span {
      display: block;
    }
  }
  .application {
    & .application_main_block {
      margin-bottom: 100px;
    }

    & .application_blocks {
      width: 100%;
      & .application_blocks__row {
        justify-content: center;

        & .application_block:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
  }

  .us_company {
    & .us_company_blocks .us_company_block {
      width: 100%;
      & .us_company__title {
        font-size: 50px;
      }
      &:first-child {
        margin-bottom: 60px;
      }

      &:last-child {
        position: relative;
        border-radius: 15px;
        padding: 50px 85px;
        display: flex;
        justify-content: center;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 15px;
          padding: 1px;
          background: linear-gradient(
            311deg,
            rgba(0, 108, 255, 0.63) -5.85%,
            rgba(0, 108, 255, 0.38) 21.26%,
            rgba(0, 108, 255, 0.21) 52.8%,
            rgba(0, 108, 255, 0.48) 108.49%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
        }

        & .us_company_block__info {
          grid-template-columns: 1fr 1.3fr;
          width: 100%;
        }
      }
    }

    & .us_company-bg {
      margin-bottom: 130px;
    }
  }

  .advantage {
    & .advantge_title {
      font-size: 50px;
      margin-bottom: 20px;
    }
    & .advantage_sub_title__block .advantage_sub_title {
      margin-right: 45px;
    }

    & .advantage-bg {
      margin: 0 -40px;
      padding: 0 40px;
      background-size: 170%;
    }

    & .advantage_block_bottom .advantage_block_row .advantage_block p {
      max-width: 330px;
      font-size: 16px;
    }
  }

   .advantage_block_bottom .advantage_block_row:first-child {
    margin-bottom: 63px !important;
  }

   .advantage_block_row .advantage_block h6 {
    font-size: 30px;
  }

  
    .advantage_block_bottom
    .advantage_block_row:last-child
    .advantage_block:first-child
    p {
    max-width: 280px !important;
  }

   .mekhanizm_5D-AI {
    background-position: center bottom;
    margin-bottom: 137px;

    & .mekhanizm_5D-AI-light {
      background-position: bottom center;
    }
    & .mekhanizm_5D-AI__blocks {
      flex-direction: column-reverse;

      & .mekhanizm_5D-AI__block {
        width: 100%;

        & .mekhanizm_5D-AI__title {
          margin-bottom: 24px;
          font-size: 50px;
        }

        & img {
          margin: 0;
        }

        &:first-child {
          text-align: center;
        }
      }
    }
  }

  .mekhanizm_coverage {
    margin-bottom: 89px;

    .mekhanizm_coverage__blocks {
      background-position: center;
    }

    &
      .mekhanizm_coverage__blocks
      .mekhanizm_coverage__block
      .mekhanizm_coverage__description {
      margin-bottom: 90px;
      text-indent: 5em;
      max-width: 420px;
    }

    & .mekhanizm_coverage__blocks .mekhanizm_coverage__block-img {
      grid-area: 2 / 1 / 3 / 2;

      & img {
        width: 398px;
      }
    }
    &
      .mekhanizm_coverage__blocks
      .mekhanizm_coverage__block
      .mekhanizm_coverage__title {
      font-size: 50px;
    }
    & .mekhanizm_coverage__blocks .mekhanizm_coverage_info__blocks {
      grid-area: 2 / 2 / 2 / 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      & .mekhanizm_coverage_info__block {
        width: 250px;
        margin: 0 !important;
        margin-top: 30px !important;
      }
    }
  }

  .modes_mekhanizm {
    &__top_blocks {
      border: none;
      padding: 0;
      margin-bottom: 55px;

      & .modes_mekhanizm__top_block {
        width: 100%;
        font-size: 40px;

        & p {
          max-width: 100%;
        }
        &:first-child {
          padding: 40px 0;
          border-top: 1px solid #ffffff40;
          border-bottom: 1px solid #ffffff40;
        }
        &:last-child {
          max-width: 460px;
          margin-top: 40px;
        }
      }
    }

    &__bottom_blocks {
      padding: 0;
      margin-bottom: 100px;

      & .modes_mekhanizm__bottom_block {
        width: 100%;
        margin: 0;

        &:nth-child(3) p {
          max-width: 470px;
        }

        &:nth-child(5) p {
          max-width: 450px;
        }

        & .modes_mekhanizm__bottom_block__title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        & p {
          margin-bottom: 70px;
        }

        & img {
          max-width: 600px;
          width: 100%;
          margin-bottom: 40px;
        }

        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:nth-child(4) {
          order: 3;
        }
        &:nth-child(5) {
          order: 6;
          & P {
            margin: 0;
          }
        }
        &:nth-child(6) {
          order: 5;
        }
      }
    }
  }

  .gravity__wrapper {
    box-shadow: none;
    & .gravity__main_blocks {
      & .gravity__main_block {
        & h6 {
          font-size: 50px;
        }
        padding: 0;
        & p {
          max-width: 450px;
          margin-bottom: 30px;

          & br {
            display: none;
          }
        }

        & .gravity_astronaut {
          left: 73px;
        }

        &:first-child {
          width: 40%;
        }
      }
    }
    & h4 {
      font-size: 250px;
    }
    & .gravity_massager {
      width: 100%;
    }
  }

  .catalog_fujiiryoki
    .catalog__fujiiryoki__items
    .catalog__fujiiryoki__item:first-child {
    margin-bottom: 50px;
  }

   .quality_reliability {
    & .quality_reliability__blocks {
      height: 1340px;
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: 70px 0;

      & .quality_reliability__blocks_light {
        width: 114%;
        height: 100%;
        background-position: -124px 268px;
      }

      & .quality_reliability_block {
        width: 100%;
        padding: 0 60px;

        &:last-child {
          margin-bottom: 50px;
        }

        & .quality_reliability_massager {
          left: 0px;
          top: 250px;
          width: 700px;
        }

        & p {
          max-width: 540px;
        }

        & h4 {
          margin-top: 0;
          margin-bottom: 24px;
        }

        & .quality_reliability_massager__info,
        & .quality_reliability_massager__info2 {
          position: relative;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          height: 90px;
          align-items: center;

          & img {
            display: none;
          }
          p {
            margin: 0;
            padding-left: 116px;
            font-size: 16px;
            font-weight: 300;
            max-width: 190px;

            & br {
              display: none;
            }
          }
        }

        & .quality_reliability_massager__info {
          background: url(../img/quality_reliability_massager__info-circle1.png)
            left center no-repeat;
          margin-bottom: 30px;
        }

        & .quality_reliability_massager__info2 {
          background: url(../img/quality_reliability_massager__info-circle2.png)
            left center no-repeat;
        }
      }
    }
  }

  .shiatsu_acupressure {
    &__top_blocks {
      padding: 0;
      border: none;

      & .shiatsu_acupressure__top_block {
        width: 100%;
        font-size: 50px;

        &:first-child {
          padding: 42px 0;
          border-top: 1px solid #ffffff40;
          border-bottom: 1px solid #ffffff40;
          margin-bottom: 40px;
        }

        & br {
          display: none;
        }

        & span {
          display: block;
        }
      }
    }

    &__bottom_blocks {
      & .shiatsu_acupressure__bottom_block {
        width: 100%;

        & .shiatsu_acupressure__demonstration_main {
          margin: 0;
          width: 100%;
          padding: 0 30px;
          box-sizing: border-box;
        }
        & > p {
          margin-top: 0;
          margin-bottom: 40px;
        }
      }
      & .shiatsu_acupressure__bottom_demonstration_blocks {
        max-width: 100%;
        justify-content: space-around;

        & .shiatsu_acupressure__bottom_demonstration_block p {
          max-width: 100%;
        }

        & .shiatsu_acupressure__bottom_demonstration_block img {
          width: 330px;
        }
      }
    }
  }

  .stretching_fitness_trainer {
    margin: 0 40px 100px;
    height: 1342px;
    border-radius: 50px;
    padding: 72px 60px;

    &__wrapper {
      position: static;

      & .stretching_fitness_trainer-who {
        left: 60px;
        bottom: 32px;
        transform: translate(0);
      }
    }

    &::before {
      display: none;
    }

    & .stretching_fitness_trainer__blocks {
      height: auto;
      align-items: flex-start;
      & .stretching_fitness_trainer__block {
        width: 100%;

        & h4 {
          font-size: 50px;
          margin-bottom: 20px;
          max-width: 560px;
        }

        & p {
          & br {
            display: none;
          }
        }

        & .stretching_fitness_trainer-man {
          width: 820px;
          bottom: 0;
        }
      }
    }
  }

  .full_body_stretch {
    margin-bottom: 70px;
    & .full_body_stretch_bg {
      display: none;
    }

    &__top_blocks {
      border: none;
      padding: 0;

      & .full_body_stretch__top_block {
        width: 100%;

        &:first-child {
          padding: 45px 0;
          font-size: 50px;
          border-top: 1px solid #ffffff40;
          border-bottom: 1px solid #ffffff40;
          margin-bottom: 40px;
        }
      }
    }

    &__bottom_blocks {
      & .full_body_stretch__bottom_block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        & .full_body_stretch_massager {
          display: none;
        }

        & .full_body_stretch__bottom_block-item {
          width: 330px;
          flex-direction: column;
          text-align: center;
          position: relative;
          padding: 20px 8px;
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: auto;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 15px;
            padding: 1px;
            background: linear-gradient(
              119.76deg,
              rgba(0, 108, 255, 0.63) -5.85%,
              rgba(0, 108, 255, 0.38) 21.26%,
              rgba(0, 108, 255, 0.21) 52.8%,
              rgba(0, 108, 255, 0.48) 108.49%
            );
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
          }

          & h6 {
            margin-top: 0;
          }

          & p {
            margin: 0 auto;
            & span {
              display: inline;
              max-width: 280px;
              margin: 0 auto;
            }
            & br {
              display: none;
            }
          }

          & img {
            align-self: center;
            margin: 0 0 14px;
            height: 96px;
          }
        }
      }
    }
  }

  .infrared_heating {
    margin-bottom: 100px;

    &__blocks {
      height: 1213px;
      flex-direction: column;
      padding-top: 80px;

      & .infrared_heating__block {
        width: 100%;

        & img {
          left: -60px;
          bottom: -36px;
          height: 756px;
        }

        & > div {
          margin: 0 auto;

          h4 {
            margin-bottom: 20px;
          }

          p {
            margin-bottom: 53px;
          }

          & div > p {
            padding: 26px 28px;
          }
        }
      }
    }
  }

  .manage_massage_easily {
    margin: 0 40px 140px;
    border-radius: 50px;
    height: 1220px;
    padding: 96px 20px;
    background-position: center bottom -50px;
    &__blocks {
      & .manage_massage_easily__block {
        width: 100%;

        & h4 {
          font-size: 50px;
          margin-bottom: 30px;
        }

        & > {
          margin-bottom: 56px;
        }

        &:first-child {
          max-width: 470px;
          margin: 0 auto;
        }

        & .manage_massage_easily_main {
          height: 606px;
        }
      }
      & .manage_massage_easily_main-light {
        width: 100%;
        height: 75%;
        background: radial-gradient(#006cff7a 12%, transparent 72%);
        bottom: -148px;
        top: auto;
        right: -75px;
        transform: rotate(338deg);
      }
    }
  }

  .chair_colors {
    margin-bottom: 67px;

    &__block__main_block {
      & .chair_colors__block-title {
        order: 1;
        width: 100%;
        margin-bottom: 86px;

        & h4 {
          font-size: 50px;
          max-width: 470px;
        }
      }

      & .chair_colors__block-color {
        order: 3;
        width: 100%;
        justify-content: center;
      }

      & .chair_colors__block-photo {
        order: 2;
        width: 100%;
        margin-bottom: 58px;
        height: 560px;

        & img {
          height: 560px;
        }
      }
    }
  }

  .flawless_build_quality {
    background-size: cover;
    background-position-x: 100%;
    height: 1012px;
    margin-bottom: 100px;
    &__blocks {
      flex-direction: column-reverse;
      & .flawless_build_quality__block {
        width: 100%;

        &:last-child {
          // max-width: 400px;
          margin: 0 auto;
        }

        & h4 {
          font-size: 50px;
          margin: 0 auto;
          margin-bottom: 36px;
          text-align: center;

          & span {
            margin-left: -195px;
            display: block;
          }
        }

        & p {
          max-width: 330px;
          margin: 0 auto;
          margin-right: 80px;
        }

        & .flawless_build_quality_massage {
          width: 100%;
          left: -50px;
        }

        & .flawless_build_quality_massagerLight {
        }
      }
    }
  }

  .delivery_assembly {
    margin-bottom: 144px;

    & h4 {
      max-width: 470px;
      margin: 0 auto;
      font-size: 45px;
      margin-bottom: 40px;

      & br {
        display: none;
      }
    }

    &__blocks {
      & .delivery_assembly__block {
        width: 100%;
        margin-bottom: 64px;

        & h6 {
          margin-bottom: 12px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .technical_specifications {
    & .technical_specifications__title {
      font-size: 45px;
      margin-bottom: 60px;
    }
    & .technical_specifications__blocks {
      & .technical_specifications__tables {
        display: block;
        margin-bottom: 87px;
        & div {
          width: 100%;

          & table {
            & td {
              width: 50%;
              padding: 11px 10px 11px 32px;

              &:first-child {
                padding: 11px 10px 11px 24px;
                width: 50%;
              }
            }
          }
        }
      }
      & .technical_specifications-images {
        & .technical_specifications-images-title {
          font-size: 30px;
          margin-bottom: 40px;
        }
      }
    }
  }

  .call_back {
    background-size: 1292px;
    background-position-y: -50px;
    background-position-x: -240px;
    height: 850px;

    & h6 {
      font-size: 40px;
      margin-bottom: 18px;
      padding-top: 300px;
    }

    &-form {
      margin: 0 auto;
    }
  }

  footer {
    & .footer__blocks_top {
      grid-template-columns: repeat(3, 1.5fr);
      padding-top: 40px;
      margin-bottom: 90px;

      & .footer__block {
        &:nth-child(3) {
          grid-area: 1 / 2 / 3 / 3;
        }
        &:nth-child(2) {
          grid-area: 1 / 3 / 2 / 4;
          margin-bottom: 53px;
        }
        &:nth-child(4) {
          grid-area: 2 / 3 / 3 / 4;
        }
        & p,
        & a {
          margin-bottom: 15px;
          line-height: 1.3em;
          &:last-child {
            margin: 0;
          }
        }
        & .footer__block__contact span {
          background-size: 18px !important;
        }
        & .footer__block__contact .footer__block__contact-email {
          margin-bottom: 42px;
        }
      }
    }

    & .footer__blocks_bottom {
      padding: 17px 0 34px 0;

      & .buy {
        margin-top: 18px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 1150px) {
  .mekhanizmy .mekhanizmy-bg {
    height: 1154px;
    background: $blackBlue;
    align-items: flex-start;
    margin-bottom: 0px;

    & .mekhanizmy_wrapper_text {
      padding-top: 60px;
      padding-left: 66px;
      max-width: 510px;
    }

    & img {
      right: 50%;
      transform: translate(50%, 0);
    }
  }
   .mekhanizmy .mekhanizmy-light {
    top: auto;
    // bottom: -274px;
    // background-position: center;
    bottom: 0;
    height: 70%;
    background-position: top;
  }

   .mekhanizmy_title {
    margin-bottom: 24px;
  }

  .mekhanizmy_wrapper {
    overflow: hidden;
    margin: 0 30px;
    padding: 0;
    margin-bottom: 90px;
  }

   .mekhanizmy .mekhanizmy_scheme {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 240px;
    top: auto;
    background-size: 300px;
    background-position: center;
  }

   .mekhanizmy .mekhanizmy_5dai {
    bottom: 410px;
    height: 64px;
    background-size: 112px;
    background-position: center;
    top: auto;
    right: 50%;
    transform: translate(50%, 0);
  }

  .gravity__wrapper {
    height: 1300px;
    background-size: 220%;
    background-position-x: 48%;
  }
  .gravity__wrapper h4 {
    font-size: 190px;
    top: 53%;
  }
}

@media (max-width: 900px) {
  .main_block .slider_text_block {
    top: 175px;
  }

  .main_block .slider_banner3 {
    width: 900px;
    float: right;
    background-position-x: -20px;
  }

  .us_company .us_company_blocks .us_company_block p {
    max-width: 445px;
  }
  .us_company .us_company-bg {
    margin-bottom: 0;
  }

  .advantage .advantage_block_bottom .advantage_block_row .advantage_block h6 {
    font-size: 30px;
  }

  .description_block .description_block-massage {
    height: 770px;
  }
  .description_block .description_block-massage img {
    height: 616px;
    top: 100px;
  }

  .catalog_fujiiryoki {
    margin-bottom: 100px;
  }

  .catalog_fujiiryoki .catalog__fujiiryoki__items .catalog__fujiiryoki__item {
    width: 510px;
  }
  .quality_reliability {
    margin-bottom: 100px;
  }
  .quality_reliability .quality_reliability__blocks {
    padding: 60px 0;
  }
  .quality_reliability .quality_reliability__blocks .quality_reliability_block {
    padding: 0 70px;
  }
  .quality_reliability
    .quality_reliability__blocks
    .quality_reliability_block
    p {
    font-size: 16px;
  }

  .shiatsu_acupressure__bottom_blocks
    .shiatsu_acupressure__bottom_block
    .shiatsu_acupressure__demonstration_main {
    display: none;
  }

  .shiatsu_acupressure__bottom_blocks
    .shiatsu_acupressure__bottom_block:first-child {
    height: 450px;
    background: url(../img/shiatsu_acupressure__demonstration-main2.png) center
      no-repeat;
    margin-bottom: 70px;
    margin-top: 58px;
  }
}
@media (max-width: 760px) {
  .video .vol_video {
    width: 90px;
    height: 90px;
  }
  .main_block .slider_banner3 {
    height: 580px;
    background-position-x: 70px;
  }

  .mekhanizm_5D-AI
    .mekhanizm_5D-AI__blocks
    .mekhanizm_5D-AI__block
    > div
    > span {
    align-items: flex-start;
  }

  .stretching_fitness_trainer
    .stretching_fitness_trainer__blocks
    .stretching_fitness_trainer__block
    .stretching_fitness_trainer-man {
    width: 750px;
  }
  .full_body_stretch__bottom_blocks
    .full_body_stretch__bottom_block
    .full_body_stretch__bottom_block-item {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 63px;

    &::before {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .mekhanizm_coverage
    .mekhanizm_coverage__blocks
    .mekhanizm_coverage__block-img
    img {
    width: 300px;
  }
  .description {
    & .description_block {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-bottom: 60px;

      & .description_block-title {
        float: initial;
        max-width: 280px;
        margin-bottom: 53px;
      }

      & .description_block-sub_title {
        float: initial;
        margin-right: 0;
      }

      & .description_block-description {
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        max-width: 100%;
      }
      & .description_block-massage {
        height: 570px;

        & img {
          top: 215px;
        }
      }
    }
  }

  .full_body_stretch__bottom_blocks
    .full_body_stretch__bottom_block
    .full_body_stretch__bottom_block-item:nth-child(1)
    p {
    max-width: 280px;
  }

  .full_body_stretch__bottom_blocks
    .full_body_stretch__bottom_block
    .full_body_stretch__bottom_block-item:nth-child(2)
    p,
  .full_body_stretch__bottom_blocks
    .full_body_stretch__bottom_block
    .full_body_stretch__bottom_block-item:nth-child(3)
    p {
    max-width: 290px;
  }

  .full_body_stretch__bottom_blocks
    .full_body_stretch__bottom_block
    .full_body_stretch__bottom_block-item
    p {
    font-size: 14px;
  }
  .gravity__wrapper {
    background-size: 167%;

    & h4 {
      font-size: 170px;
    }
    & .gravity__main_blocks {
      padding: 0 15px;
      & .gravity__main_block {
        width: 100%;

        & h6 {
          margin-bottom: 20px;
        }

        .gravity_astronaut {
          left: 50px;
          width: 150px;
        }
        &:first-child {
          transform: rotateY(180deg);
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 681px) {
  footer .footer__blocks_top .footer__block .block__social,
  footer
    .footer__blocks_top
    .footer__block
    .footer__block__contact
    .footer__block__contact-phone
    > span,
  .mekhanizm_coverage-1,
  .mekhanizm_coverage-2,
  .mekhanizm_coverage-3,
  .mekhanizm_coverage-4 {
    display: none;
  }
}

@media (max-width: 680px) {
  .video {
    margin-bottom: 80px;

    & .vol_video {
      border: none;
    }
  }

  .header {
    & .header__logo .sub__logo {
      font-size: 13px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }

    & .header__info .heder__info_block_contacts__bottom .header_menu__top {
      padding: 12px 15px 0;
    }
  }
  .header .header__info .heder__info_block .heder__info_block_menu ul {
    padding: 0 50px;
    margin: 0;
  }
  .header .header__info .heder__info_block {
    justify-content: start;
    margin-top: 25%;
  }
  .header .header__info .heder__info_block .heder__info_block_menu ul li {
    text-align: left;
    margin: 18px 0;
    &:last-child {
      margin-bottom: 0;
    }
    & a {
      font-size: 30px;
    }
  }
  .heder__info_block_contacts {
    width: 100%;
    padding: 0 50px;
  }
  .header
    .header__info
    .heder__info_block_contacts__bottom
    .heder__info_block_contacts__bottom-social {
    margin-left: 0;
    margin-top: 26px;
  }
  .header
    .header__info
    .heder__info_block_contacts__bottom
    .heder__info_block_contacts__bottom-social
    a {
    margin: 0;
    margin-right: 20px;
    width: 30px;
    height: 30px;
  }

  .header
    .header__info
    .heder__info_block_contacts__bottom
    .header_menu__top
    .menu-close {
    width: 24px;
    height: 24px;
  }

  .header .header__info .heder__info_block_contacts__bottom {
    margin-top: 70px;
    text-align: left;
  }
  .header .header__info .heder__info_block_contacts__bottom h6 {
    font-size: 30px;
    margin-bottom: 11px;
  }
  .header .header__info .heder__info_block_contacts__bottom > a {
    font-size: 20px;
  }
  .header .header__info .heder__info_block_contacts__bottom > span {
    font-size: 14px;
  }

  .header {
    padding-top: 12px;

    & .header__logo img {
      width: 127px;
    }

    & .header__call_back {
      display: none;
    }
    & .header__call_back-button {
      display: block;
      background: url(../img/phone-call-back.svg) center no-repeat !important;
      font-size: 0;
      border: 0;
      width: 23px;
      height: 23px;
      padding: 0;
      border-radius: 0;
    }
  }

  .header__right_blocks .burger_menu {
    width: 31px;
    height: 23px;
    justify-content: space-between;
    cursor: pointer;
    & span {
      height: 3px;
    }
  }
  .main_block {
    margin-bottom: 47px;

    & .slider_text_block {
      top: 98px;
      left: 15px;

      & .slider_title,
      & .slider_model {
        font-size: 30px;
      }
      & p {
        font-size: 16px;
      }
    }
  }

  .description {
    & .description_block {
      background-position: left;
      margin-bottom: 100px;
      margin-top: 47px;
      & .description_block-title {
        font-size: 40px;
      }
      & .description_block-sub_title {
        font-size: 18px;
        width: 260px;
      }

      & .description_block-description {
        font-size: 16px;
        max-width: 340px;
        bottom: -28px;
      }

      & .description_block-massage img {
        height: 540px;
      }
    }
  }
  .catalog_fujiiryoki {
    padding: 0;
  }
  .application {
    & .application_main_block {
      margin-bottom: 60px;
      & h4 {
        font-size: 40px;
        text-align: left;
        max-width: 350px;
        margin-bottom: 50px;

        & span {
          display: inline;
        }
      }
    }

    & .application_blocks__row {
      flex-wrap: wrap;
      margin-bottom: 0 !important;

      & .application_block {
        max-width: 100%;
        width: 100%;
        height: auto;
        margin-right: 0 !important;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 40px 30px;
        &::before {
          background: linear-gradient(
            -22deg,
            rgba(0, 108, 255, 0.63) -5.85%,
            rgba(0, 108, 255, 0.38) 21.26%,
            rgba(0, 106, 255, 0.097) 52.8%,
            rgba(0, 108, 255, 0.48) 108.49%
          );
        }
        & .application_block__icon {
          width: 37px;
          margin: auto 0;
          margin-right: 26px;
        }

        & p {
          max-width: 200px;
        }
      }
    }
    & .application_blocks__row:last-child {
      & .application_block:first-child p {
        max-width: 170px;
      }
    }
  }

  .us_company {
    margin-bottom: 80px;
    & .us_company_blocks {
      margin-bottom: 0;

      & .us_company_block {
        & .us_company__title {
          font-size: 40px;
          margin-bottom: 20px;
        }

        & p {
          font-size: 16px;
          max-width: 350px;
        }

        &:first-child {
          margin-bottom: 42px;
        }

        &:last-child {
          padding: 31px 26px;
        }

        & .us_company_block__info .info__block span {
          font-size: 40px;
        }
      }
    }
    & .us_company-bg {
      height: 202px;
      margin-top: 50px;
      background-size: 160%;
    }
  }

  .advantage {
    & .advantage-bg {
      background-position: bottom;
    }
    & .advantge_title {
      padding: 0;
      text-indent: 0;
      font-size: 40px;
      line-height: 1.1em;
      max-width: 310px;

      & span {
        text-indent: 0 !important;
      }
    }
    & .advantage_sub_title {
      font-size: 16px;
      max-width: 210px;
      text-indent: 0;
      margin-left: 0;
    }
    .advantage_block_top {
      height: 765px;
    }

    & .advantage_sub_title__block {
      margin-right: 0;

      & button {
        position: absolute;
        bottom: -75px;
        left: 15px;
      }
    }

    & .advantage_block_bottom {
      margin-bottom: 126px;
      margin-top: -20px;
      & .advantage_block_row {
        flex-wrap: wrap;

        & .advantage_block {
          width: 100%;
          margin-bottom: 50px;

          & h6 {
            font-size: 25px;
            max-width: 300px;
            line-height: 1.3em;
            margin-bottom: 12px;
          }

          & p {
            font-size: 16px;
            font-weight: 300;
            width: 100%;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .mekhanizmy {
    & .mekhanizmy-bg {
      overflow: hidden;
      height: 868px;
      border-radius: 20px;
      & img {
        transform: translate(51%, 26px);
        height: 455px;
      }
      & .mekhanizmy_wrapper_text {
        padding: 45px 15px 0;

        & .mekhanizmy_title {
          font-size: 40px;
          line-height: 1.2em;
          margin-bottom: 20px;

          & span {
            display: block;
          }
        }

        & .mekhanizmy_description {
          font-size: 16px;
          max-width: 305px;
        }
      }
    }
    & .mekhanizmy_scheme {
      bottom: 50px;
    }
    & .mekhanizmy_5dai {
      bottom: 210px;
    }
    & .mekhanizmy-light {
      background-position-x: 50%;
      background-position-y: inherit;
      left: 0;
    }
  }
  .mekhanizmy_wrapper {
    margin-bottom: 100px;
    margin: 0 15px;
    margin-bottom: 100px;
    border-radius: 20px;
  }

  .mekhanizm_5D-AI {
    background-size: 480px;
    padding-bottom: 40px;
    margin-bottom: 100px;
    & .mekhanizm_5D-AI__blocks {
      & .mekhanizm_5D-AI__block {
        & .mekhanizm_5D-AI__title {
          font-size: 40px;
          margin-bottom: 20px;
        }

        & .mekhanizm_5D-AI__description {
          font-size: 16px;
          margin-bottom: 31px;
          line-height: 1.5em;
        }

        & > div {
          & > span {
            font-size: 16px;
            line-height: 1.5em;
            &:last-child {
              max-width: 295px;
              margin: 0;
            }
          }
        }
        & img {
          margin-left: -20px;
          max-width: 385px;
          padding: 0;
        }
      }
    }
  }

  .mekhanizm_coverage {
    & .mekhanizm_coverage__blocks {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 -15px;
      padding: 0 15px;
      & .mekhanizm_coverage__block {
        & .mekhanizm_coverage__title {
          font-size: 40px;
          margin-bottom: 20px;
          & span {
            display: block;
          }
        }
        & .mekhanizm_coverage__description {
          text-indent: 0;
          font-size: 16px;
          line-height: 1.4em;
          margin-bottom: 26px;
        }
      }
      & .mekhanizm_coverage__block-img {
        grid-area: 2 / 1 / 3 / 3;

        & .mekhanizm_coverage__block-mobile {
          max-width: 300px;
          margin: 0 auto;
          position: relative;

          & > div {
            position: absolute;
            width: 24px;
            height: 24px;
            display: block;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);
            border-radius: 50%;

            &::after,
            &::before {
              display: block;
              content: "";
              width: 7px;
              height: 1px;
              background: #201e1d;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            }
            &::before {
              transform: translate(-50%, -50%) rotate(90deg);
            }

            &.active {
              &::before {
                transform: translate(-50%, -50%) rotate(0deg);
              }
            }
          }
          & .mekhanizm_coverage_info__block-mobile.active {
            width: 200px;
            height: 144px;

            &::before {
              transform: translate(-50%, -50%) rotate(0deg);
            }
          }
          & .mekhanizm_coverage_info__block-mobile {
            width: 0;
            height: 0;
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            top: 28px;
            transition: all 250ms ease-in-out;
            z-index: 0;

            & span {
              position: absolute;
              background: $blue;
              color: $white;
              height: 34px;
              width: 72px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            & img {
              width: 200px;
            }
          }

          & .mekhanizm_coverage-1 {
            top: 134px;
            right: 100px;
            z-index: 3;
            transition: all 250ms ease-in-out;
            & .mekhanizm_coverage_info__block-mobile {
              float: right;
            }
          }
          & .mekhanizm_coverage-2 {
            top: 270px;
            left: 60px;
            z-index: 2;
            transition: all 250ms ease-in-out;
          }
          & .mekhanizm_coverage-3 {
            bottom: 343px;
            right: 65px;
            z-index: 1;
            transition: all 250ms ease-in-out;

            & .mekhanizm_coverage_info__block-mobile {
              float: right;
            }
          }
          & .mekhanizm_coverage-4 {
            bottom: 200px;
            left: 100px;
            transition: all 250ms ease-in-out;
          }
        }
      }
    }
  }

  .modes_mekhanizm__top_blocks {
    margin-bottom: 17px;

    & .modes_mekhanizm__top_block {
      font-size: 40px;
      margin-top: 30px;
      & p {
        font-size: 16px;
      }

      & img {
        margin-bottom: 30px;
      }
    }
  }
  .modes_mekhanizm__bottom_blocks {
    & .modes_mekhanizm__bottom_block {
      & p {
        font-size: 16px;
        max-width: 334px;
      }
    }
  }
  .mekhanizm_coverage .mekhanizm_coverage__blocks {
    .mekhanizm_coverage_info__blocks {
      display: none;
    }
  }

  .gravity__wrapper {
    height: 1174px;

    & h4 {
      font-size: 140px;
    }
    .gravity_massagerLight {
      background: linear-gradient(
        0deg,
        #ffffff00 0%,
        rgba(0, 108, 255, 0.7) 30%,
        #ffffff00 134%
      );
      bottom: 89px;
      height: 614px;
    }
    & .gravity__main_blocks {
      & .gravity__main_block {
        & button {
          position: absolute;
          left: 50%;
          bottom: 80px;
          transform: translateX(-50%);
          width: 246px;
        }
        & .gravity_astronaut {
          width: 72px;
          top: 200px;
        }

        & h6 {
          font-size: 40px;
        }
        & p {
          font-size: 16px;
        }
      }
    }
  }

  .catalog_fujiiryoki {
    & h4 {
      font-size: 40px;
      text-align: left;
      margin-bottom: 40px;
    }
    & .catalog__fujiiryoki__items .catalog__fujiiryoki__item {
      width: 100%;
      padding: 31px;

      & img {
        width: auto;
        height: 283px;
      }
    }
  }

  .quality_reliability {
    & .quality_reliability__blocks {
      padding: 40px 0;
      height: 1160px;
      border-radius: 20px;

      & .quality_reliability__blocks_light {
        width: 203%;
        height: 99%;
        background-position: -341px 424px;
      }

      & .quality_reliability_block {
        padding: 0 24px;
        position: unset;

        & h4 {
          font-size: 40px;
          margin-bottom: 5px;
        }

        & .quality_reliability_massager {
          left: -65px;
          top: auto;
          width: 479px;
          bottom: -60px;
        }
      }
    }
  }

  .shiatsu_acupressure {
    & .shiatsu_acupressure__top_blocks {
      & .shiatsu_acupressure__top_block {
        font-size: 40px;

        & P {
          font-size: 16px;
        }
      }
    }
    &
      .shiatsu_acupressure__bottom_blocks
      .shiatsu_acupressure__bottom_block
      > p {
      font-size: 16px;
      margin-bottom: 50px;
    }
    & .shiatsu_acupressure__bottom_demonstration_blocks {
      justify-content: space-between;
      & .shiatsu_acupressure__bottom_demonstration_block {
        width: 46%;
        background: none;
        & img {
          width: 100%;
        }
        & p {
          max-width: 200px;
          font-size: 16px;
        }
      }
    }
  }
  .stretching_fitness_trainer {
    margin: 0;
    padding: 40px 29px;
    height: 855px;
    margin-bottom: 100px;
    border-radius: 20px;

    & .stretching_fitness_trainer__blocks .stretching_fitness_trainer__block {
      & h4 {
        font-size: 40px;
      }

      & p {
        font-size: 16px;
        font-weight: 300;
      }

      & .stretching_fitness_trainer-man {
        width: 383px;
        right: -45px;
      }
    }
    & .stretching_fitness_trainer__wrapper .stretching_fitness_trainer-who p {
      font-size: 12px;
      max-width: 333px;
    }

    & .stretching_fitness_trainer__wrapper .stretching_fitness_trainer-who {
      left: 30px;
    }
  }

  .full_body_stretch {
    .main__button {
      width: 309px;
    }
    & .full_body_stretch__top_blocks {
      & .full_body_stretch__top_block {
        &:first-child {
          font-size: 40px;
          padding: 40px 0;
          margin-bottom: 23px;
        }

        & p {
          font-size: 16px;

          & br {
            display: none;
          }
        }
      }
    }

    & .full_body_stretch__bottom_blocks {
      margin-top: 50px;

      & .full_body_stretch__bottom_block {
        & .full_body_stretch__bottom_block-item {
          & img {
            height: 115px;
            width: 115px;
          }
          padding: 0;
          margin: 0 auto 50px;
        }
      }
    }
  }

  .infrared_heating {
    &__blocks {
      margin: 0 -15px;
      padding-top: 50px;
      border-radius: 20px;
      height: 894px;
      & .infrared_heating__block {
        & > div {
          padding: 0 28px;
          max-width: 100%;

          & h4 {
            font-size: 40px;
          }

          & p {
            font-size: 16px;
            margin-bottom: 33px;

            & br {
              display: none;
            }
          }
          & div > p {
            font-size: 14px;
            padding: 23px 17px;
          }
        }

        & img {
          height: 480px;
          left: -50px;
          bottom: -10px;
        }
      }
    }
  }
  .manage_massage_easily {
    margin: 0 15px 100px;
    border-radius: 20px;
    padding: 40px 17px;
    height: 760px;
    background-size: 220%;

    & .manage_massage_easily__blocks {
      & .manage_massage_easily__block {
        &:first-child {
          & h4 {
            font-size: 40px;
            margin-bottom: 20px;
          }
          & > p {
            font-size: 16px;
            margin-bottom: 55px;

            br {
              display: none;
            }
          }

          & .manage_massage_easily__block__bottom-main {
            & p {
              font-size: 14px;
            }

            & .manage_massage_easily__block__bottom {
              & > div {
                width: 53px;
                height: 53px;
                box-shadow: none;
              }

              & p {
                br {
                  display: none;
                }
              }
            }
          }
        }

        & .manage_massage_easily_main {
          height: 369px;
          bottom: -66px;
          right: -94px;
        }
      }
    }
    & .manage_massage_easily_main-light {
      width: 600px;
      height: 600px;
      right: -110px;
      transform: rotate(0deg);
    }
  }

  .chair_colors {
    &__block__main_block {
      & .chair_colors__block-title {
        margin-bottom: 35px;
        & h4 {
          font-size: 40px;
        }
      }
      & .chair_colors__block-photo {
        height: 417px;
        & img {
          height: 417px;
          left: -55px;

          &:last-child {
            left: -95px;
          }
        }
      }
    }
  }
  .flawless_build_quality {
    height: 546px;
    background-size: 900px;
    background-position-x: -153px;
    & .flawless_build_quality__blocks {
      padding: 0;

      & .flawless_build_quality__block {
        & .flawless_build_quality_massage {
          width: auto;
          height: 320px;
        }
        & p {
          float: none;
          margin: 0;
        }

        &:last-child {
          & h4 {
            font-size: 40px;
            text-align: left;
            margin-bottom: 30px;
            & span {
              margin: 0;
            }
          }

          & p {
            font-size: 16px;
          }
        }
      }
    }
  }

  .delivery_assembly {
    margin-bottom: 20px;
    & h4 {
      font-size: 25px;
      max-width: 260px;
      margin-bottom: 28px;
    }

    & .delivery_assembly__blocks .delivery_assembly__block {
      margin-bottom: 120px;

      & p {
        font-size: 16px;
      }
    }
  }
  .technical_specifications {
    & .technical_specifications__title {
      text-align: left;
      font-size: 40px;
      margin-bottom: 40px;
      & span {
        & br {
          display: block;
        }
      }
    }
    & .technical_specifications__blocks {
      margin-bottom: 80px;
      & .technical_specifications__tables {
        margin-bottom: 102px;
        & div {
          & table {
            & td {
              vertical-align: middle !important;
              &:last-child {
                padding: 10px 11px;
                box-sizing: border-box;
                text-align: center;
              }
              &:first-child {
                font-weight: 300;
                padding: 11px 10px 11px 15px;
              }
            }
            &:last-child {
              & td {
                &:last-child {
                  & br {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      & .technical_specifications-images {
        & .technical_specifications-images-title {
          font-size: 40px;
        }
        & .technical_specifications-images__blocks{
          display: flex;
          flex-wrap: wrap;
          & > img {
            display: none;
          }
          & .technical_specifications-images__block{
            display: block;
            width: 100%;

            &:nth-child(2){
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
  .call_back {
    background-size: 932px;
    background-position-y: -50px;
    background-position-x: -478px;
    height: 650px;

    & h6 {
      padding-top: 160px;
      text-align: left;
    }
  }

  footer {
    & .footer__blocks_top {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 45px;

      & .footer__block__logo .sub__logo {
        margin-bottom: 30px;
      }

      & .footer__block .footer__block__social {
        display: none;
      }

      & .footer__block {
        margin: 0 !important;
        border-top: 1px solid rgba(110, 110, 115, 0.8);
        border-bottom: 1px solid rgba(110, 110, 115, 0.8);

        &:first-child {
          border: none;
        }
        &:nth-child(3) {
          margin-bottom: 40px !important;
        }
        &:nth-child(4) {
          border: none;
        }

        & .footer__block-list {
          height: 0;
          overflow: hidden;

          &.active {
            height: 100%;
          }
        }
        & > a:last-child,
        & p:last-child {
          margin-bottom: 29px;
        }

        & h6 {
          margin-bottom: 0;
          padding: 28px 0;
          position: relative;
          display: flex;
          align-items: center;

          &.active {
            &::after {
              transform: rotate(45deg);
            }
            &::before {
              transform: rotate(135deg);
            }
          }

          &::after,
          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 19px;
            height: 3px;
            background: $white;
            border-radius: 5px;
            right: 15px;
            transition: all 150ms ease-in-out;
          }

          &::before {
            transform: rotate(90deg);
          }
        }

        &:nth-child(4) > h6 {
          display: none;
        }

        & .footer__block__contact .footer__block__contact-phone {
          background: none;
          padding: 0;
          font-size: 20px;

          & a {
            padding-left: 4px;
          }

          & p {
            margin-bottom: 40px;
            padding-left: 4px;
          }
          & > span {
            display: block;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
          }
        }
        & .footer__block__contact .footer__block__contact-email {
          display: none;
        }
        & .footer__block__contact button {
          display: none;
        }
        & .footer__block__contact-phone .footer__block__social {
          display: flex;

          & a {
            height: 30px;
            width: 30px;
            background-size: 30px 30px;
            padding: 0;
            margin: 0;
            margin-right: 15px;
          }
        }
        & .block__social {
          display: flex;
          align-items: center;
          & span {
            display: block;
            margin-right: 13px;
          }
        }
      }
    }

    & .footer__blocks_bottom {
      border: none;
      justify-content: center;
      font-size: 14px;
      padding-top: 0;
      margin-top: 0;
      & a {
        order: 3;
      }
      & p {
        order: 2;
        margin-bottom: 10px;
      }
      & .buy {
        order: 1;
        justify-content: space-between;
        margin-bottom: 64px;
      }
    }
  }
}

@media (max-width: 660px) {
  .flawless_build_quality__blocks
    .flawless_build_quality__block
    .flawless_build_quality_massagerLight {
    position: absolute;
    left: -180px;
    top: -91px;
    z-index: -1;
    width: 828px;
    opacity: 0.7;
  }
  .quality_reliability
    .quality_reliability__blocks
    .quality_reliability_block:last-child
    p {
    & br {
      display: none;
    }
  }

  .gravity__wrapper .gravity_massagerLight {
    bottom: 140px;
  }
}

@media (max-width: 570px) {
  .gravity__wrapper {
    height: 872px;
    background: linear-gradient(0deg, #0c0c0c 25%, rgba(12, 12, 12, 0) 37%),
      linear-gradient(
        340deg,
        #0c0c0c 9.38%,
        rgba(0, 0, 0, 0) 41%,
        rgba(12, 12, 12, 0) 41%
      ),
      linear-gradient(176.87deg, #0c0c0c 27%, rgba(12, 12, 12, 0) 35%),
      url(../img/gravity-bg.png) center no-repeat;
    background-position-x: 48%;
    background-size: 167%;
    & h4 {
      font-size: 88px;
    }
    & .gravity_massagerLight {
      height: 370px;
    }
    & .gravity_massagerLight {
      height: 370px;
      bottom: 200px;
    }
  }
  .shiatsu_acupressure__bottom_blocks
    .shiatsu_acupressure__bottom_block:first-child {
    background-size: contain;
    margin: 0;
  }

  .main_block .slider_text_block button{
    width: 213px;
    font-size: 14px;
    height: 40px;
  }
}
@media (max-width: 420px) {
  .main_block .slider_banner3 {
    height: 580px;
    background-position-x: 34px;
    width: 751px;
  }

}
#root {
  overflow: hidden;
}
