@import "./color.scss";

.wrapper__notfound {
  background: $black url(../img/notfound-bg.png) center no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  text-align: center;
  padding: 0 15px;
  font-size: 18px;

  & h4 {
    color: $blue;
    font-size: 200px;
    font-weight: 700;
    margin-bottom: 13px;
  }

  & span.subtitle {
    font-size: 60px;
    margin-bottom: 50px;
  }

  & p {
    margin-bottom: 20px;
  }

  & span.link {
    & a {
      color: $blue;

      & br {
        display: none;
      }
      &:hover {
        color: $blueActive;
      }
    }
  }

  & br:first-child {
    display: none;
  }
}

@media (max-width: 1200px) {
  .wrapper__notfound {
    background: $black url(../img/notfound-bg-m.png) center no-repeat;
    background-position-y: -60px;
    background-size: cover;
    & h4 {
      font-size: 150px;
      margin-bottom: 18px;
    }

    & span.subtitle {
      font-size: 25px;
      margin-bottom: 18px;
    }

    & p {
      font-size: 16px;
    }

    & br:first-child {
      display: block;
    }
    & br:last-child {
      display: none;
    }
  }
}

@media (max-width: 680px) {
  .wrapper__notfound {
    & h4 {
      font-size: 90px;
      margin-bottom: 13px;
    }

    & span.subtitle {
      font-size: 25px;
      margin-bottom: 20px;
    }
    & p {
      max-width: 342px;
      margin-bottom: 40px;
      & br {
        display: none !important;
      }
    }
    & span.link {
      font-size: 16px;
      & a {
        & br {
          display: block;
        }
      }
    }
  }
}
