
.animation_active-opacity{
    opacity: 0 !important;
}

.animation_active-bottom{
    opacity: 0 !important;
    transform: translateY(15px) !important;
}

.animation_active-bottom-scroll{
    opacity: 0 !important;
    transform: translateY(15px) !important;
}

.animation_active-right-scroll{
    opacity: 0 !important;
    transform: translateX(20px) !important;
}

.animation_active-left-scroll{
    opacity: 0 !important;
    transform: translateX(-20px) !important;
}

.animation_active-opacity-scroll{
    opacity: 0 !important;
}

.animation_active-opacity2-scroll{
    opacity: 0 !important;
}