@import "./color.scss";

.wrapper__showrooms {
  & .main__block_showrooms {
    position: relative;
    height: 745px;
    width: 100%;
    background: url(../img/showrooms/showrooms-bg.png) center no-repeat;
    background-size: cover;
    margin-bottom: 130px;
  }

  & .choice__main_blocks__home {
    display: flex;
    margin-bottom: 90px;
    & .choice__main_block__home {
      border: 1px solid #b7b7b7;
      overflow: hidden;
      background: #fbfbfb;
      box-sizing: border-box;
      border-radius: 50px;
      padding: 50px 70px 62px 70px;

      & h5 {
        font-size: 45px;
        color: $blackText;
        font-weight: 300;
        line-height: 1.2em;
        margin-bottom: 10px;
        margin-bottom: 115px;
      }

      & h6 {
        color: $blackText;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 21px;
      }

      & p {
        color: $grayText;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 33px;
      }
      & > span {
        display: block;
        font-size: 18px;
        margin-bottom: 40px;
        color: $grayText;
      }

      & a {
        color: $blue;
        font-size: 22px;
        font-weight: 600;
        text-decoration: none;
        padding-left: 30px;
        background: url(../img/phone-call-back.svg) left center no-repeat;
        background-size: 18px;
      }

      & div {
        text-align: end;
        position: relative;
        margin-bottom: 145px;
        margin-right: 30px;

        & span {
          position: absolute;
          display: block;
          bottom: -75px;
          left: 50px;
          width: 126px;
          height: 126px;
          backdrop-filter: blur(13px);
          background: radial-gradient(
            126.06% 83.22% at 92.06% 42.95%,
            rgba(238, 238, 238, 0.25) 0%,
            rgba(240, 240, 240, 0.0559896) 39.89%,
            rgba(198, 198, 198, 0) 65.67%,
            rgba(0, 115, 255, 0.25) 85.29%
          );
          border-radius: 50%;
        }
      }

      &:nth-child(1) {
        width: 470px;
        flex-shrink: 0;
        padding: 36px 40px 80px 40px;

        & h5 {
          margin-bottom: 52px;
        }

      }

      &:nth-child(2) {
        flex-grow: 1;
        margin-left: 30px;

        & h5 {
          margin-bottom: 40px;
          color: $blackText;
        }
      }

      & ul {
        margin: 0;
        padding: 0;
        & li {
          list-style: none;
          padding-left: 52px;
          font-size: 22px;
          color: $grayText;
          margin-bottom: 28px;
          height: 34px;
          display: flex;
          align-items: center;

          &:nth-child(1) {
            background: url(../img/test-drive/assembly.svg) no-repeat left
              center;
          }

          &:nth-child(2) {
            background: url(../img/test-drive/delivery.svg) no-repeat left
              center;
          }

          &:nth-child(3) {
            background: url(../img/test-drive/home.svg) no-repeat left center;
          }

          &:nth-child(4) {
            background: url(../img/test-drive/day.svg) no-repeat left center;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  & .main__block_showrooms__city {
    margin-bottom: 60px;
    & h5 {
      font-size: 40px;
      font-weight: 400;
      color: $blackText;
      margin-bottom: 50px;
    }
    & .showrooms__city__blocks {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;

      & > div {
        & > div {
          margin-bottom: 40px;

          & .showrooms__city__block {
            display: none;

            &.active {
              display: block;
            }
          }
          & > span {
            color: $blackText;
            font-size: 30px;
            font-weight: 300;
            background: url(../img/showrooms/arrow.svg) right center no-repeat;
            padding-right: 25px;
            cursor: pointer;

            &.active {
              background: url(../img/showrooms/arrowUP.svg) right center
                no-repeat;
            }
          }
          & .showrooms__city__block {
            margin-top: 20px;
            & span {
              display: block;
              font-size: 18px;
              color: $grayText;
              margin-bottom: 20px;
              max-width: 370px;
            }
            & .city1 {
              font-weight: 300;
              color: $blackText;
              margin-bottom: 8px;
            }
            & .city4 {
              margin-bottom: 50px;
            }
            & span:last-child {
              margin-bottom: 60px;
            }
          }
        }
      }
    }
  }

  & #map {
    margin-bottom: 60px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .wrapper__showrooms {
    & .main__block_showrooms {
      height: 630px;
      min-height: 100%;

    }
    & .choice__main_blocks__home {
      margin-bottom: 60px;
      & .choice__main_block__home {
        & h5 {
          font-size: 40px;
          margin-bottom: 77px;
        }

        & p {
          font-size: 18px;
          margin-bottom: 40px;
        }
        & ul li {
          font-size: 18px;
        }
        &:first-child{
          & h5 {
            & br{
              display: none;
            }
          }
        }
      }
    }
    & .main__block_showrooms__city h5 {
      font-size: 40px;
    }
    & .main__block_showrooms__city .showrooms__city__blocks {
      grid-template-columns: repeat(2, 1fr);
    }

    & #map {
      margin-bottom: 100px;
      border-radius: 20px;
      & img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper__showrooms {
    & .choice__main_blocks__home {
      flex-wrap: wrap;
      & .choice__main_block__home {
        width: 100% !important;
        margin: 0 0 30px !important;
        padding: 70px 68px;

        & div {
          max-width: 261px;
          margin: 0 auto 57px;

          & span {
            left: -58px;
            width: 137px;
            height: 137px;
            bottom: -66px;
          }
        }

        & p {
          & br {
            display: none;
          }
        }

        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .wrapper__showrooms {
    & .main__block_showrooms {
      height: 580px;
      margin-bottom: 80px;
    }
    & .choice__main_blocks__home {
      margin-bottom: 70px;
      & .choice__main_block__home {
        padding: 36px 10px 38px 40px;
        border-radius: 20px;
        overflow: hidden;

        & h5 {
          font-size: 25px;
          margin-bottom: 30px;
        }
        & p {
          font-size: 16px;
        }

        & ul li {
          font-size: 16px;
        }

        & a {
          font-size: 18px;
        }
      }
    }

    & .main__block_showrooms__city {
      & h5 {
        font-size: 30px;
        margin-bottom: 30px;
        line-height: 1.3em;
      }
      & .showrooms__city__blocks {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0px;
        & div {
          width: 100%;
          & span {
            font-size: 25px;
          }
        }
      }
    }

    & #map {
      width: 100%;
      height: 230px;
      overflow: hidden;
      margin-bottom: 60px;
      background-position-y: -119px;
      & img {
        width: auto;
      }
    }
  }
}

@media (max-width: 420px) {
  .wrapper__showrooms {
    & .main__block_showrooms {
      background: url(../img/showrooms/showrooms-m-bg.png) center no-repeat;
      background-size: cover;
    }
  }

  .wrapper__showrooms .choice__main_blocks__home .choice__main_block__home div {
    max-width: 154px;
    margin: 0 auto;

    & img {
      width: 100%;
    }
  }
  .wrapper__showrooms
    .choice__main_blocks__home
    .choice__main_block__home
    div
    span {
    width: 74px;
    height: 74px;
    left: -30px;
    bottom: -34px;
  }

  .wrapper__showrooms .choice__main_blocks__home .choice__main_block__home p {
    max-width: 266px;
  }
}
