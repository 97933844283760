@import "./color.scss";

.wrapper__test_drive {
  & .main__block_test_drive {
    position: relative;
    height: 745px;
    width: 100%;
    background: url(../img/test-drive/testdrive-bg.png) no-repeat;
    background-position-y: bottom;
    background-size: cover;
    margin-bottom: 130px;
    transition: all 350ms ease-in-out;

    & img {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 160px);
      transition: opacity 250ms ease-in-out;
    }
  }
  & .description__block_test_drive {
    margin-bottom: 13px;
    & .description__test_drive_blocks {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      & .description__test_drive_block {
        width: 50%;

        & h6 {
          font-size: 60px;
          font-weight: 300;
          color: $blackText;
          line-height: 1em;
          margin-bottom: 30px;
          transition: all 350ms ease-in-out;

          & span {
            color: $blue;
          }
        }
        & p {
          margin: 0;
          font-size: 22px;
          color: $grayText;
          line-height: 1.3em;
          transition: all 450ms ease-in-out;
        }

        & img {
          transition: all 450ms ease-in-out;
        }

        &:last-child {
          text-align: end;
        }
      }
    }
  }

  & .choice__block_test_drive {
    & > h6 {
      font-size: 45px;
      font-weight: 300;
      color: $blackText;
      margin-bottom: 30px;
      text-align: center;
      transition: all 350ms ease-in-out;
    }
    & .choice__test_drive_main {
      height: 95px;
      margin-bottom: 100px;
    }

    & .choice__test_drive__wrapper {
      transition: all 250ms ease-in-out;
      &.active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;

        & .choice__test_drive span {
          height: 33px;
          font-size: 14px;
          max-width: 200px;
        }
        & .choice__test_drive {
          box-shadow: 4px 0 8px 0px #00000057;
          margin-bottom: 10px;
          margin-top: 5px;
          height: 50px;
          max-width: 450px;
          padding: 0 13px 0 13px;
          & div {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
    & .choice__test_drive {
      max-width: 560px;
      margin: 0 auto;
      height: 95px;
      background: $grayWhite;
      border-radius: 50px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 20px 0 20px;
      transition: all 250ms ease-in-out;

      & div {
        width: 50%;
        font-size: 22px;
        font-weight: 600;
        position: relative;
        text-align: center;
        cursor: pointer;
      }

      & span {
        position: absolute;
        background: $blue;
        max-width: 250px;
        width: 100%;
        height: 55px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 22px;
        font-weight: 600;
        transition: all 250ms ease-in-out;

        &.active {
          transform: translateX(calc(100% + 24px));
        }
      }
    }
    & .choice__main_wrapper {
      & .choice__main_blocks__home {
        display: flex;
        margin-bottom: 80px;
        & .choice__main_block__home {
          border: 1px solid #b7b7b7;
          background: #fbfbfb;
          box-sizing: border-box;
          border-radius: 50px;
          padding: 50px 70px 0 70px;
          transition: all 350ms ease-in-out;

          & h5 {
            font-size: 45px;
            color: $blackText;
            font-weight: 300;
            line-height: 1.2em;
            margin-bottom: 10px;
            transition: all 350ms ease-in-out;
          }

          & p {
            margin: 0;
            font-size: 22px;
            color: $grayText;
            line-height: 1.3em;
            margin-bottom: 65px;
            transition: all 450ms ease-in-out;
          }
          & div {
            text-align: end;
            position: relative;
            margin-bottom: 145px;
            margin-right: 8px;
            transition: all 450ms ease-in-out;

            & img{
              position: relative;
              z-index: 2;
            }
            & span {
              position: absolute;
              display: block;
              bottom: -55px;
              z-index: 3;
              left: 23px;
              width: 126px;
              height: 126px;
              backdrop-filter: blur(46px);
              background: radial-gradient(
                126.06% 83.22% at 92.06% 42.95%,
                rgba(238, 238, 238, 0.25) 0%,
                rgba(240, 240, 240, 0.0559896) 39.89%,
                rgba(198, 198, 198, 0) 65.67%,
                rgba(0, 115, 255, 0.25) 85.29%
              );
              border-radius: 50%;
            }
          }

          &:nth-child(1) {
            width: 470px;
            flex-shrink: 0;
          }
          &.showrooms {
            &:nth-child(2) {
              & h5 {
                margin-bottom: 40px;
              }
            }
          }
          &:nth-child(2) {
            flex-grow: 1;
            margin-left: 30px;

            & h5 {
              margin-bottom: 94px;
            }
          }

          & ul {
            margin: 0;
            padding: 0;
            & li {
              list-style: none;
              padding-left: 52px;
              font-size: 22px;
              color: $grayText;
              margin-bottom: 28px;
              height: 34px;
              display: flex;
              align-items: center;
              transition: all 350ms ease-in-out;

              &:nth-child(1) {
                background: url(../img/test-drive/assembly.svg) no-repeat left center;
              }

              &:nth-child(2) {
                background: url(../img/test-drive/delivery.svg) no-repeat left center;
              }

              &:nth-child(3) {
                background: url(../img/test-drive/home.svg) no-repeat left center;
              }

              &:nth-child(4) {
                background: url(../img/test-drive/day.svg) no-repeat left center;
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      & .how-work__main_block {
        & > h6 {
          font-size: 60px;
          font-weight: 300;
          color: $blackText;
          text-align: center;
          margin-bottom: 60px;
          transition: all 350ms ease-in-out;
        }
        & .how-work__main_blocks__home-main {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 40px;
          & .how-work__main_blocks__home {
            width: 33.333%;
            height: 100%;
            background: #fbfbfb;
            border: 1px solid #b7b7b7;
            border-radius: 10px;

            &:nth-child(1) {
              transition: all 350ms ease-in-out;
            }

            &:nth-child(2) {
              border-left: 0;
              border-right: 0;
              transition: all 550ms ease-in-out;
            }

            &:nth-child(3) {
              transition: all 750ms ease-in-out;
            }
            & .how-work__main_blocks__home-top {
              width: 100%;
              padding: 40px;
              border-bottom: 1px solid #b7b7b7;
              & span {
                font-size: 100px;
                font-weight: 300;
                color: $blue;
                margin-bottom: 10px;
              }
              & h5 {
                font-size: 45px;
                font-weight: 300;
                color: $blackText;
              }
            }
            & .how-work__main_blocks__home-bottom {
              padding: 40px 36px;
              font-size: 22px;
              font-weight: 300;
              color: $grayText;
            }
          }
        }

        .how-work__result {
          display: flex;
          align-items: center;
          color: $white;
          background: linear-gradient(122.22deg, #006cff 23.36%, #17a1ff 69.28%);
          border-radius: 50px;
          height: 277px;
          padding: 50px 0 50px 50px;
          margin-bottom: 100px;

          & .how-work__result__block {
            position: relative;
            flex-grow: 1;

            & h6 {
              font-size: 60px;
              font-weight: 300;
              margin-bottom: 30px;
              transition: all 350ms ease-in-out;
            }
            & p {
              margin: 0;
              font-size: 22px;
              transition: all 350ms ease-in-out;
            }

            &:first-child {
              max-width: 724px;
              width: 100%;
            }

            &:last-child {
              width: 297px;
              flex-shrink: 0;
              display: flex;
              justify-content: center;

              & img {
                transform: translateY(35px);
                transition: opacity 450ms ease-in-out;
              }
            }
          }
        }
      }

      & .block_test_drive-from {
        display: flex;
        background: #fbfbfb;
        border: 1px solid #b7b7b7;
        border-radius: 50px;
        align-items: center;
        margin-bottom: 80px;
        height: 518px;
        & .block_test_drive-from__block {
          width: 50%;

          & > div > div {
            display: flex;

            & .call_back-checkbox {
              margin: 0 5px;
            }

            & p {
              font-size: 14px;
              color: $grayText;
              max-width: 300px;
              margin-bottom: 30px;
              margin-left: 5px;

              & a {
                color: $blackGray;
              }
            }
          }

          &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
              transform: translate(10px, 30px);
            }
          }

          &:last-child {
            max-width: 370px;
            margin-left: 20px;

            & > div {
              display: flex;
              flex-direction: column;
            }
          }

          & h6 {
            font-size: 30px;
            color: $blackText;
            font-weight: 300;
            margin-bottom: 10px;
          }

          & input {
            outline: none;
          }

          & input.form-text {
            border: 1px solid #c5c3c6;
            color: #939699;
            background: #fbfbfb;
            border-radius: 10px;
            padding: 11px 17px;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 18px;
            &::placeholder {
              color: #c5c3c6;
              font-weight: 300;
              font-size: 18px;
            }

            &:last-child {
              margin-bottom: 24px;
            }
          }
          & input.form-button {
            height: 44px;
            background: $blue;
            color: $white;
            font-weight: 600;
            border-radius: 10px;
            font-size: 18px;
            border: none;

            &:disabled {
              opacity: 0.3;
            }
          }
        }
      }
      & .choice__main_blocks__home.showrooms .choice__main_block__home {
        &:first-child h5 {
          margin-bottom: 115px;
        }
        &:last-child h5 {
          margin-bottom: 50px;
        }
      }
      & .choice__main_blocks__home.showrooms .choice__main_block__home p {
        margin-bottom: 20px;
        max-width: 505px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper__test_drive {
    & .main__block_test_drive {
      height: 630px;
      min-height: 100%;
    }

    & .description__block_test_drive .description__test_drive_blocks {
      & .description__test_drive_block {
        & h6 {
          font-size: 50px;
          margin-bottom: 20px;
        }

        & p {
          font-size: 18px;
        }
      }
    }
    & .choice__block_test_drive {
      & > h6 {
        font-size: 40px;
      }

      & .choice__main_wrapper {
        & .choice__main_blocks__home {
          margin-bottom: 60px;
          & .choice__main_block__home {
            & h5 {
              font-size: 40px;
              margin-bottom: 12px;
            }
            &:nth-child(2) h5 {
              margin-bottom: 22px;
            }
            & p {
              font-size: 18px;
              margin-bottom: 40px;
            }
            & ul li {
              font-size: 18px;
            }
          }
        }
        & .how-work__main_block > h6 {
          font-size: 50px;
          margin-bottom: 30px;
        }
        & .how-work__main_block .how-work__main_blocks__home-main .how-work__main_blocks__home:nth-child(2) {
          border-left: 1px solid #b7b7b7;
          border-right: 1px solid #b7b7b7;
        }

        &
          .how-work__main_block
          .how-work__main_blocks__home-main
          .how-work__main_blocks__home
          .how-work__main_blocks__home-top
          h5 {
          font-size: 30px;
        }
        &
          .how-work__main_block
          .how-work__main_blocks__home-main
          .how-work__main_blocks__home
          .how-work__main_blocks__home-bottom {
          font-size: 16px;

          & br {
            display: none;
          }
        }
        & .how-work__main_block .how-work__result .how-work__result__block h6 {
          font-size: 40px;
          margin-bottom: 22px;
        }
        & .how-work__main_block .how-work__result .how-work__result__block p {
          font-size: 18px;
        }

        & .how-work__main_block .how-work__result {
          margin-bottom: 60px;
          border-radius: 20px;
        }
        .block_test_drive-from {
          height: 440px;
          border-radius: 20px;
        }

        & .block_test_drive-from__block {
          margin: 0;
        }
        & .block_test_drive-from .block_test_drive-from__block h6 {
          font-size: 25px;
          line-height: 1.1em;
          margin-bottom: 20px;
          max-width: 305px;

          & br {
            display: none;
          }
        }
        & .block_test_drive-from .block_test_drive-from__block:first-child img {
          width: 100%;
          transform: translate(10px, 10px);
        }
        & .block_test_drive-from .block_test_drive-from__block input.form-text {
          font-size: 16px;
          font-weight: 300;

          &::placeholder {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper__test_drive {
    & .description__block_test_drive {
      margin-bottom: 60px;
      & .description__test_drive_blocks {
        & .description__test_drive_block {
          width: 100%;

          &:first-child {
            margin-bottom: 88px;
          }

          &:last-child {
            text-align: center;
          }

          & h6 {
            line-height: 1.1em;
          }
          & p {
            max-width: 400px;

            & br {
              display: none;
            }
          }
        }
      }
    }
    & .choice__block_test_drive {
      & > h6 {
        max-width: 609px;
        margin: 0 auto 30px;
      }
      & .choice__main_wrapper {
        & .choice__main_blocks__home {
          flex-wrap: wrap;
          & .choice__main_block__home {
            width: 100% !important;
            margin: 0 0 30px !important;
            padding: 70px 68px;
            border-radius: 20px;

            & h5 {
              & br {
                display: none;
              }
            }

            & div {
              max-width: 261px;
              margin: 0 auto 57px;

              & span {
                left: -58px;
                width: 137px;
                height: 137px;
                bottom: -66px;
              }
            }

            & p {
              & br {
                display: none;
              }
            }

            &:last-child {
              margin: 0 !important;
            }
          }
        }
        & .how-work__main_block .how-work__main_blocks__home-main {
          margin-bottom: 60px;
          flex-direction: column;
          & .how-work__main_blocks__home {
            display: flex;
            width: 100%;
            border-radius: 20px;

            & .how-work__main_blocks__home-top {
              width: 50%;
              border-bottom: none;
              border-right: 1px solid #b7b7b7;
              display: flex;
              align-items: center;
              padding: 37px 44px;
              & span {
                margin-bottom: 0;
                line-height: 1em;
                width: 50px;
                margin-right: 30px;
              }
            }

            & .how-work__main_blocks__home-bottom {
              width: 50%;
              display: flex;
              align-items: center;

              & P {
                text-align: center;
                max-width: 275px;
                margin: 0 auto;
              }
            }
            &:nth-child(1) p {
              max-width: 235px;
            }
            &:nth-child(2) p {
              max-width: 270px;
            }
            &:nth-child(3) p {
              max-width: 245px;
            }
          }
        }
        & .how-work__main_block .how-work__result .how-work__result__block p {
          & br {
            display: none;
          }
        }
        & .block_test_drive-from .block_test_drive-from__block h6 {
          // padding-left: 35px;
        }
        & .block_test_drive-from .block_test_drive-from__block:last-child > div {
          max-width: 278px;
          // padding: 0 35px;
        }
        /*  & .block_test_drive-from .block_test_drive-from__block > div > div p {
          font-size: 10px;
        } */
      }
    }
  }
}

@media (max-width: 760px) {
  .wrapper__test_drive
    .description__block_test_drive
    .description__test_drive_blocks
    .description__test_drive_block:first-child {
    & br {
      display: none;
    }
  }

  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .block_test_drive-from
    .block_test_drive-from__block
    h6 {
    padding-left: 35px;
    max-width: 300px;
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .block_test_drive-from
    .block_test_drive-from__block:last-child
    > div {
    margin: 0 auto;

    & p {
      font-size: 10px;
      max-width: 215px;
    }
  }

  .wrapper__test_drive {
    & .main__block_test_drive {
      height: 480px;
      margin-bottom: 140px;
      & img {
        transform: translate(-50%, 175px);
      }
    }

    & .description__block_test_drive {
      margin-bottom: 15px;

      & .description__test_drive_blocks {
        & .description__test_drive_block {
          &:first-child {
            margin-bottom: 40px;
          }

          & h6 {
            font-size: 40px;
          }
          & p {
            font-size: 16px;
            max-width: 330px;
          }
        }
      }
    }
    & .choice__block_test_drive {
      & > h6 {
        font-size: 25px;
      }
      & .choice__test_drive_main {
        height: 130px;
      }
      & .choice__test_drive {
        max-width: 345px;
        height: 69px;
        margin-bottom: 60px;
        padding: 0 13px;

        & div {
          font-size: 16px;
        }
        & span {
          font-size: 16px;
          width: 166px;
          height: 44px;
        }
      }
      & .choice__test_drive__wrapper.active .choice__test_drive {
        max-width: 345px;
      }
      & .choice__test_drive span.active {
        width: 148px;
      }

      & .choice__main_wrapper {
        & .choice__main_blocks__home {
          & .choice__main_block__home {
            padding: 47px 34px;
            overflow: hidden;

            & > h5 {
              margin-bottom: 6px;
              font-size: 25px;
            }
            & p {
              font-size: 16px;
            }

            &:nth-child(2) h5 {
              margin-bottom: 18px;
            }

            & ul li {
              font-size: 16px;
            }
          }
          &.showrooms {
            & .choice__main_block__home {
              &:first-child {
                padding-bottom: 95px !important;
                & h5 {
                  margin-bottom: 48px !important;
                }
              }
              &:last-child {
                padding: 31px 34px 34px;
                & h5 {
                  margin-bottom: 16px !important;
                }
                & p:last-child {
                  margin: 0;
                }
              }
            }
          }
        }

        & .how-work__main_block {
          & > h6 {
            font-size: 40px;
          }

          & .how-work__main_blocks__home-main {
            & .how-work__main_blocks__home {
              flex-direction: column;

              &:nth-child(1) {
                border-radius: 20px 20px 0px 0px;
              }
              &:nth-child(2) {
                border-radius: 0px;
                border-top: none;
                border-bottom: none;
              }
              &:nth-child(3) {
                border-radius: 0px 0px 20px 20px;
              }

              & .how-work__main_blocks__home-top {
                border-right: none;
                border-bottom: 1px solid #b7b7b7;
                width: 100%;
                justify-content: center;
                flex-direction: column;
                & span {
                  margin-right: 0;
                  margin-bottom: 8px;
                }
                & h5 {
                  font-size: 25px;
                  & br {
                    display: none;
                  }
                }
              }
              & .how-work__main_blocks__home-bottom {
                width: 100%;
              }
            }
          }
          & .how-work__result {
            flex-direction: column-reverse;
            height: 100%;
            padding: 20px 30px 54px;

            & .how-work__result__block {
              & h6 {
                margin-bottom: 18px;
              }
              & p {
                font-size: 16px;
              }
            }
          }
        }
        & .block_test_drive-from {
          flex-direction: column;
          padding: 55px 0;
          height: 100%;
          margin-left: 0;

          & .block_test_drive-from__block {
            width: 100%;
            margin-left: 0 !important;

            & img {
              width: 85% !important;
              transform: translate(30px, 0) !important;
            }

            & input.form-text {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .wrapper__test_drive .main__block_test_drive img {
    transform: translate(-50%, 155px);
    height: auto;
    width: 100%;
  }
  .wrapper__test_drive
    .description__block_test_drive
    .description__test_drive_blocks
    .description__test_drive_block:last-child
    img {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .wrapper__test_drive .main__block_test_drive img {
    transform: translate(-50%, 120px);
  }

  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .choice__main_blocks__home
    .choice__main_block__home
    div {
    max-width: 154px;
    margin: 0 auto;

    & img {
      width: 100%;
    }
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .choice__main_blocks__home
    .choice__main_block__home
    div
    span {
    width: 74px;
    height: 74px;
    left: -30px;
    bottom: -34px;
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .choice__main_blocks__home
    .choice__main_block__home
    p {
    max-width: 266px;
  }

  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__main_blocks__home-main
    .how-work__main_blocks__home
    .how-work__main_blocks__home-bottom {
    padding: 40px 20px;
    & p {
      max-width: 100%;

      & br {
        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__main_blocks__home-main
    .how-work__main_blocks__home:nth-child(2)
    p {
    max-width: 270px;
  }
  .wrapper__test_drive
    .choice__block_test_drive
    .choice__main_wrapper
    .how-work__main_block
    .how-work__main_blocks__home-main
    .how-work__main_blocks__home:nth-child(3)
    p {
    max-width: 250px;
    & br {
      display: none !important;
    }
  }
  .wrapper__test_drive .choice__block_test_drive .choice__test_drive_main {
    margin: 0;
  }
}
