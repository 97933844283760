@import "./color.scss";
.wrapper__thanks {
  background: $black;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $white;
  text-align: center;

  & br{
      display: none;
  }

  & img{
      margin-bottom: 40px;
  }
  & h2{
      font-size: 40px;
      margin-bottom: 20px;
  }
  & p{
      font-size: 22px;
      color: $grayText;
  }

}

@media (max-width: 680px) {
    .wrapper__thanks {
        & img{
            width: 100px;
            margin-bottom: 30px;
        }
        & br{
            display: block;
        }
        & h2{
            font-size: 25px;
        }
        & p{
            font-size: 14px;
            color: $grayText;
        }
    }
}