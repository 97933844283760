$black: #0c0c0c;
$blue: #006cff;
$blueActive: #0052c5;
$bluehover: #4694FF;
$gray: #8f8f8f;
$grayNew: #939699;
$white: #ffffff;
$blackBlue: #131820;
$blackGray: #151921;
$grayText: #717477;
$blackText: #201e1d;
$grayWhite: #f5f5f5;